import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "../ui/dialog"
import { Button } from "../ui/button"

const TransactionModal = ({
  isOpen,
  onClose,
  title,
  transaction,
  setTransaction,
  handleSave,
  isSaving,
  type,
  ProjectSelector,
}) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && isOpen && !isSaving) {
        e.preventDefault();
        validateAndSave();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
      // Reset errors when modal opens
      setErrors({});
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, isSaving]);

  if (!transaction) return null;

  const validateAndSave = () => {
    const newErrors = {};
    
    if (!transaction.amount) {
      newErrors.amount = 'Amount is required';
    }

    if (transaction.is_recurring && !transaction.recurring_frequency) {
      newErrors.recurring_frequency = 'Frequency is required for recurring payments';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleSave();
    }
  };

  // Only consider it a duplicate if we have a memo or project_id (indicating it came from an existing transaction)
  const isDuplicating = (transaction.memo || transaction.project_id) && !transaction.id;

  return (
    <Dialog open={isOpen} onOpenChange={(open) => {
      if (!open) {
        setErrors({});
        onClose();
      }
    }}>
      <DialogContent className="sm:max-w-[425px] shadow-lg">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>
            Enter the details for this transaction. Press Enter to save.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="space-y-2">
            <label className="text-sm font-medium">Memo</label>
            <input
              type="text"
              value={transaction?.memo || ''}
              onChange={(e) => setTransaction(prev => ({ ...prev, memo: e.target.value }))}
              className="w-full p-2 border rounded"
              placeholder="Add a memo (optional)"
              disabled={isSaving}
              autoFocus
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Project</label>
            <ProjectSelector
              value={transaction?.project_id || ''}
              onChange={(value) => setTransaction(prev => ({ ...prev, project_id: value }))}
              disabled={isSaving}
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Amount</label>
            <input
              type="number"
              value={transaction?.amount || ''}
              onChange={(e) => {
                setTransaction(prev => ({ ...prev, amount: e.target.value }));
                if (errors.amount) {
                  setErrors(prev => ({ ...prev, amount: undefined }));
                }
              }}
              className={`w-full p-2 border rounded ${errors.amount ? 'border-red-500 focus:ring-red-500' : ''}`}
              placeholder="Amount"
              disabled={isSaving}
            />
            {errors.amount && (
              <p className="text-xs text-red-500 mt-1">{errors.amount}</p>
            )}
          </div>

          {/* Recurring Payment Section */}
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="is_recurring"
                checked={transaction?.is_recurring || false}
                onChange={(e) => {
                  const isRecurring = e.target.checked;
                  setTransaction(prev => ({
                    ...prev,
                    is_recurring: isRecurring,
                    // If recurring is enabled, set default values
                    recurring_frequency: isRecurring ? 'monthly' : null,
                    recurring_end_date: isRecurring ? null : null,
                    next_recurring_date: isRecurring ? prev.date : null
                  }));
                }}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                disabled={isSaving}
              />
              <label htmlFor="is_recurring" className="text-sm font-medium text-gray-700">
                This is a recurring payment
              </label>
            </div>

            {transaction?.is_recurring && (
              <div className="mt-4 grid grid-cols-2 gap-4">
                <div>
                  <label className="text-sm font-medium">Frequency</label>
                  <select
                    value={transaction?.recurring_frequency || 'monthly'}
                    onChange={(e) => {
                      const frequency = e.target.value;
                      setTransaction(prev => ({
                        ...prev,
                        recurring_frequency: frequency,
                        // Always ensure next_recurring_date is set when frequency changes
                        next_recurring_date: prev.date
                      }));
                      if (errors.recurring_frequency) {
                        setErrors(prev => ({ ...prev, recurring_frequency: undefined }));
                      }
                    }}
                    className={`w-full p-2 border rounded ${errors.recurring_frequency ? 'border-red-500' : ''}`}
                    disabled={isSaving}
                  >
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="quarterly">Quarterly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                  {errors.recurring_frequency && (
                    <p className="text-xs text-red-500">{errors.recurring_frequency}</p>
                  )}
                </div>

                <div>
                  <label className="text-sm font-medium">End Date (Optional)</label>
                  <input
                    type="date"
                    value={transaction?.recurring_end_date || ''}
                    onChange={(e) => setTransaction(prev => ({ 
                      ...prev, 
                      recurring_end_date: e.target.value || null,
                      // Ensure next_recurring_date is still set
                      next_recurring_date: prev.date
                    }))}
                    className="w-full p-2 border rounded"
                    min={transaction?.date}
                    disabled={isSaving}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">
              {transaction?.is_recurring ? 'Start Date' : 'Date'}
            </label>
            <input
              type="date"
              value={transaction?.date || ''}
              onChange={(e) => {
                // The date input gives us the date in local timezone
                const newDate = e.target.value;
                setTransaction(prev => ({ 
                  ...prev, 
                  date: newDate,
                  // Update next_recurring_date if this is a recurring transaction
                  next_recurring_date: prev.is_recurring ? newDate : null
                }));
              }}
              onClick={(e) => e.target.showPicker()}
              className="w-full p-2 border rounded"
              disabled={isSaving}
            />
            <div className="flex gap-2 mt-1">
              <button
                type="button"
                onClick={() => {
                  const date = new Date();
                  date.setDate(1);
                  const newDate = date.toISOString().split('T')[0];
                  setTransaction(prev => ({ 
                    ...prev, 
                    date: newDate,
                    // Update next_recurring_date if this is a recurring transaction
                    next_recurring_date: prev.is_recurring ? newDate : null
                  }));
                }}
                className="text-xs text-blue-600 hover:text-blue-800"
                disabled={isSaving}
              >
                1st of month
              </button>
              <span className="text-gray-300">|</span>
              <button
                type="button"
                onClick={() => {
                  const date = new Date();
                  date.setMonth(date.getMonth() + 1);
                  date.setDate(0);
                  const newDate = date.toISOString().split('T')[0];
                  setTransaction(prev => ({ 
                    ...prev, 
                    date: newDate,
                    // Update next_recurring_date if this is a recurring transaction
                    next_recurring_date: prev.is_recurring ? newDate : null
                  }));
                }}
                className="text-xs text-blue-600 hover:text-blue-800"
                disabled={isSaving}
              >
                Last day of month
              </button>
            </div>
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Payment Method</label>
            <select
              value={transaction?.payment_method || 'Invoice'}
              onChange={(e) => setTransaction(prev => ({ ...prev, payment_method: e.target.value }))}
              className="w-full p-2 border rounded"
              disabled={isSaving}
            >
              <option value="Invoice">Invoice</option>
              <option value="Cash">Cash</option>
              <option value="Bank Transfer">Bank Transfer</option>
            </select>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button 
            onClick={validateAndSave} 
            disabled={isSaving}
            className="bg-green-600 hover:bg-green-700 text-white"
          >
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionModal; 