import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  HomeIcon, 
  UsersIcon, 
  CogIcon, 
  ChevronDownIcon, 
  ArrowRightOnRectangleIcon,
  CurrencyDollarIcon,
  BriefcaseIcon,
  LinkIcon,
  ClockIcon
} from '@heroicons/react/24/outline';
import { supabase } from '../utils/supabaseClient';

const SidebarItem = ({ icon: Icon, label, to, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  
  return (
    <Link 
      to={to} 
      onClick={onClick} 
      className={`flex items-center space-x-2 py-2 px-4 rounded-lg cursor-pointer transition-colors duration-150 ${
        isActive 
          ? 'bg-blue-50 text-[#307FE2] font-medium' 
          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
      }`}
    >
      <Icon className={`w-5 h-5 ${isActive ? 'text-[#307FE2]' : 'text-gray-400'}`} />
      <span>{label}</span>
    </Link>
  );
};

const RoleToggle = ({ currentRole, onRoleChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const roles = ['admin', 'agency', 'team'];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        View as: {currentRole.charAt(0).toUpperCase() + currentRole.slice(1)}
        <ChevronDownIcon className="w-5 h-5 ml-2" />
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full mt-2 origin-top-right bg-white rounded-md shadow-lg">
          {roles.map((role) => (
            <button
              key={role}
              onClick={() => {
                onRoleChange(role);
                setIsOpen(false);
              }}
              className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            >
              {role.charAt(0).toUpperCase() + role.slice(1)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

function Sidebar({ userRole, user, onRoleChange }) {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Helper function to get display name
  const getDisplayName = () => {
    if (user?.user_metadata?.full_name) {
      return user.user_metadata.full_name;
    } else if (user?.user_metadata?.name) {
      return user.user_metadata.name;
    } else if (user?.email) {
      return user.email.split('@')[0]; // Use the part before @ in email
    }
    return 'User';
  };

  // Helper function to format role
  const formatRole = (role) => {
    return role.charAt(0).toUpperCase() + role.slice(1);
  };

  const adminNavItems = [
    { name: 'Cashflow', href: '/cashflow', icon: CurrencyDollarIcon },
    { name: 'Project Connections', href: '/project-connections', icon: LinkIcon },
    { name: 'Pricing', href: '/pricing', icon: CogIcon },
    { name: 'Users', href: '/users', icon: UsersIcon },
  ];

  const teamNavItems = [
    { name: 'Projects', href: '/projects', icon: HomeIcon },
    { name: 'Pods', href: '/pods', icon: UsersIcon },
  ];

  return (
    <div className="fixed left-0 h-screen w-64 bg-white text-gray-800 p-4 flex flex-col">
      <div className="mb-6">
        <img src="/inovo_logo.svg" alt="Inovo Logo" className="w-32 mb-4" />
      </div>
      <div>
        {userRole === 'admin' && (
          <div className="mb-6">
            <RoleToggle currentRole={userRole} onRoleChange={onRoleChange} />
          </div>
        )}
        <nav className="space-y-6">
          {/* Team Section */}
          <div>
            <h3 className="px-4 text-xs font-semibold text-gray-500 uppercase tracking-wider mb-2">
              Team
            </h3>
            <div className="space-y-1">
              {teamNavItems.map((item) => (
                <SidebarItem
                  key={item.name}
                  icon={item.icon}
                  label={item.name}
                  to={item.href}
                />
              ))}
            </div>
          </div>

          {/* Admin Section - Only shown to admin users */}
          {userRole === 'admin' && (
            <div>
              <h3 className="px-4 text-xs font-semibold text-gray-500 uppercase tracking-wider mb-2">
                Admin
              </h3>
              <div className="space-y-1">
                {adminNavItems.map((item) => (
                  <SidebarItem
                    key={item.name}
                    icon={item.icon}
                    label={item.name}
                    to={item.href}
                  />
                ))}
                <SidebarItem icon={ClockIcon} label="Time Tracking" to="/time-tracking" />
              </div>
            </div>
          )}
        </nav>
      </div>
      <div className="mt-auto">
        <SidebarItem 
          icon={ArrowRightOnRectangleIcon} 
          label="Logout" 
          to="#" 
          onClick={handleLogout}
        />
        <div className="text-sm mt-2 mb-2">
          <p>Welcome, {getDisplayName()}</p>
          {userRole && (
            <p className="text-xs text-gray-500">{formatRole(userRole)}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
