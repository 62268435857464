import { supabase } from '../utils/supabaseClient';

const projectConnectionService = {
  // Get all projects from Supabase
  async getSupabaseProjects() {
    try {
      let allProjects = [];
      let page = 0;
      const pageSize = 1000;
      let hasMore = true;

      while (hasMore) {
        const { data, error, count } = await supabase
          .from('projects')
          .select(`
            *,
            clients:client_id (
              id,
              name,
              notion_client_id
            )
          `, { count: 'exact' })
          .range(page * pageSize, (page + 1) * pageSize - 1)
          .order('created_at', { ascending: false });

        if (error) throw error;
        
        if (!data || data.length === 0) {
          hasMore = false;
        } else {
          allProjects = [...allProjects, ...data];
          if (data.length < pageSize) {
            hasMore = false;
          } else {
            page++;
          }
        }
      }
      
      return { 
        data: allProjects.map(project => ({
          ...project,
          client_name: project.clients?.name || 'No Client',
          toggl_id: project.toggl_id
        }))
      };
    } catch (error) {
      console.error('Error fetching Supabase projects:', error);
      throw error;
    }
  },

  // Update project's Toggl connection
  async updateTogglConnection(projectId, togglData) {
    try {
      const { data, error } = await supabase
        .from('projects')
        .update({ 
          toggl_id: togglData.id?.toString(),
          toggl_project_name: togglData.name,
          toggl_client_name: togglData.client_name || null,
          toggl_client_id: togglData.client_id?.toString() || null,
          updated_at: new Date().toISOString()
        })
        .eq('id', projectId)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error updating Toggl connection:', error);
      throw error;
    }
  },

  // Remove Toggl connection
  async removeTogglConnection(projectId) {
    try {
      const { data, error } = await supabase
        .from('projects')
        .update({ 
          toggl_id: null,
          toggl_project_name: null,
          toggl_client_name: null,
          toggl_client_id: null,
          updated_at: new Date().toISOString()
        })
        .eq('id', projectId)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error removing Toggl connection:', error);
      throw error;
    }
  }
};

export default projectConnectionService; 