import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { supabase } from '../utils/supabaseClient';
import MonthSelector from '../components/cashflow/MonthSelector';
import StatsBoxes from '../components/timetracking/StatsBoxes';
import NotionIcon from '../components/NotionIcon';

const TogglIcon = ({ className }) => (
  <svg 
    className={className} 
    viewBox="0 0 24 24" 
    role="img" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 0C5.373 0 0 5.37 0 12s5.373 12 12 12c6.63 0 12-5.37 12-12S18.63 0 12 0zm-.84 4.67h1.68v8.36h-1.68V4.67zM12 18.155c-3.24-.002-5.865-2.63-5.864-5.868 0-2.64 1.767-4.956 4.314-5.655v1.71c-1.628.64-2.698 2.21-2.695 3.96 0 2.345 1.903 4.244 4.248 4.243 2.344-.002 4.244-1.903 4.243-4.248 0-1.745-1.07-3.312-2.694-3.95V6.63c2.55.7 4.314 3.018 4.314 5.66 0 3.24-2.626 5.864-5.865 5.864z"/>
  </svg>
);

const TimeTracking = () => {
  const [timeData, setTimeData] = useState([]);
  const [projectTotals, setProjectTotals] = useState([]);
  const [userTotals, setUserTotals] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState(null);
  const [projectMap, setProjectMap] = useState(new Map());
  const [showUnmatchedEntries, setShowUnmatchedEntries] = useState(false);
  const [userMap, setUserMap] = useState(new Map());

  useEffect(() => {
    fetchTimeData();
  }, [selectedDate]);

  const fetchTimeData = async () => {
    setLoading(true);
    setError(null);
    setDebugInfo(null);
    try {
      const startOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
      const endOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);

      // First, check if we can connect to Supabase with a simple count
      const { count: totalCount, error: testError } = await supabase
        .from('time_tracking')
        .select('*', { count: 'exact', head: true });

      if (testError) {
        throw new Error(`Database connection test failed: ${testError.message}`);
      }

      setDebugInfo(prev => ({ ...prev, totalRecords: totalCount }));

      // Fetch user data first to build the mapping
      const { data: userMappingData, error: userError } = await supabase
        .from('users')
        .select('id, name, toggl_user_id')
        .not('toggl_user_id', 'is', null);

      if (userError) {
        console.error('Error fetching user data:', userError);
        throw userError;
      }

      console.log('User mapping data:', userMappingData);

      // Create a map of Toggl user IDs to user names
      // Convert toggl_user_id to number since it's stored as bigint
      const newUserMap = new Map(
        userMappingData.map(user => [Number(user.toggl_user_id), { name: user.name, id: user.id }])
      );
      setUserMap(newUserMap);

      console.log('User map:', Object.fromEntries(newUserMap));

      console.log('Fetching time data for:', {
        startOfMonth: startOfMonth.toISOString(),
        endOfMonth: endOfMonth.toISOString()
      });

      const { data, error, count } = await supabase
        .from('time_tracking')
        .select(`
          id,
          project_id,
          toggl_entry_id,
          toggl_project_id,
          toggl_user_id,
          description,
          duration,
          start_time,
          stop_time,
          created_at
        `)
        .gte('start_time', startOfMonth.toISOString())
        .lte('start_time', endOfMonth.toISOString());

      if (error) throw error;

      // Fetch project and client details separately
      const projectIds = [...new Set(data.map(entry => entry.project_id))].filter(Boolean);
      const togglProjectIds = [...new Set(data.map(entry => entry.toggl_project_id))].filter(Boolean);
      
      let projectDetails = [];
      let togglProjects = [];

      // Fetch projects by project_id
      if (projectIds.length > 0) {
        const { data: projects, error: projectError } = await supabase
          .from('projects')
          .select(`
            id,
            title,
            appetite,
            toggl_project_name,
            notion_project_id,
            client:client_id (
              id,
              name
            )
          `)
          .in('id', projectIds);

        if (projectError) throw projectError;
        projectDetails = projects;
      }

      // Fetch projects by toggl_id
      if (togglProjectIds.length > 0) {
        const { data: projects, error: togglError } = await supabase
          .from('projects')
          .select(`
            id,
            title,
            toggl_id,
            appetite,
            toggl_project_name,
            notion_project_id,
            client:client_id (
              id,
              name
            )
          `)
          .in('toggl_id', togglProjectIds.map(id => id.toString()));

        if (togglError) throw togglError;
        togglProjects = projects;
      }

      // Create maps for quick lookups
      const newProjectMap = new Map(
        projectDetails.map(project => [
          project.id, 
          { 
            title: project.title,
            clientName: project.client?.name,
            appetite: project.appetite,
            notionProjectId: project.notion_project_id
          }
        ])
      );
      setProjectMap(newProjectMap);

      const togglProjectMap = new Map(
        togglProjects.map(project => [
          project.toggl_id,
          {
            title: project.title,
            clientName: project.client?.name,
            appetite: project.appetite,
            notionProjectId: project.notion_project_id
          }
        ])
      );

      // Enhance time tracking data with project details
      const enhancedData = data.map(entry => ({
        ...entry,
        project: newProjectMap.get(entry.project_id) || null,
        togglProject: togglProjectMap.get(entry.toggl_project_id?.toString()) || null
      }));

      setDebugInfo(prev => ({
        ...prev,
        queriedRecords: count,
        dateRange: {
          start: startOfMonth.toISOString(),
          end: endOfMonth.toISOString()
        },
        sampleData: enhancedData?.slice(0, 2)
      }));

      console.log('Raw time tracking data:', enhancedData);

      if (!enhancedData || enhancedData.length === 0) {
        console.log('No time tracking data found for the selected period');
        setTimeData([]);
        setProjectTotals([]);
        setUserTotals([]);
        return;
      }

      // Process data for the graph (daily totals)
      const dailyTotals = processDataForGraph(enhancedData);
      console.log('Processed daily totals:', dailyTotals);
      setTimeData(dailyTotals);

      // Process project totals
      const projectData = processProjectTotals(enhancedData);
      console.log('Processed project totals:', projectData);
      setProjectTotals(projectData);

      // Process user totals
      const userData = processUserTotals(enhancedData);
      console.log('Processed user totals:', userData);
      setUserTotals(userData);

    } catch (error) {
      console.error('Error fetching time data:', error);
      setError(error.message);
      setDebugInfo(prev => ({ ...prev, error: error.message }));
    } finally {
      setLoading(false);
    }
  };

  const processDataForGraph = (data) => {
    const dailyTotals = {};
    
    // First, initialize all days of the month
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const formattedDate = `${month + 1}/${day}`;
      const isWeekend = date.getDay() === 0 || date.getDay() === 6;
      
      dailyTotals[formattedDate] = { 
        date: formattedDate, 
        hours: 0,
        projectId: null,
        isWeekend
      };
    }
    
    // Then add the actual data
    data.forEach(entry => {
      const date = new Date(entry.start_time);
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`;
      const hoursSpent = entry.duration / 3600000;
      
      if (dailyTotals[formattedDate]) {
        dailyTotals[formattedDate].hours += hoursSpent;
        dailyTotals[formattedDate].projectId = entry.project_id;
      }
    });

    return Object.values(dailyTotals).sort((a, b) => {
      const [aMonth, aDay] = a.date.split('/').map(Number);
      const [bMonth, bDay] = b.date.split('/').map(Number);
      return (aMonth - bMonth) || (aDay - bDay);
    });
  };

  const processProjectTotals = (data) => {
    const projectTotals = {};
    
    data.forEach(entry => {
      const togglProjectId = entry.toggl_project_id;
      if (!togglProjectId) return; // Skip entries without toggl_project_id
      
      const hoursSpent = entry.duration / 3600000; // Convert milliseconds to hours
      
      if (!projectTotals[togglProjectId]) {
        // Try to get project details from our database
        const matchedProject = entry.project_id ? projectMap.get(entry.project_id) : null;
        const togglProject = entry.togglProject;
        
        let projectName;
        let isUnmatchedEntry = false;
        let budgetHours = 0;
        let appetite = matchedProject?.appetite || togglProject?.appetite;
        let notionProjectId = matchedProject?.notionProjectId || togglProject?.notionProjectId;

        if (matchedProject?.title) {
          projectName = matchedProject.title;
        } else if (togglProject?.title) {
          projectName = togglProject.title;
        } else {
          projectName = entry.description || `Project ${togglProjectId}`;
          isUnmatchedEntry = true;
        }

        // Calculate budget hours based on appetite (25 hours per week)
        if (appetite) {
          const appetiteWeeks = {
            'Half Week': 0.5,
            '1 Week': 1,
            '2 Weeks': 2,
            '4 Weeks': 4,
            '6 Weeks': 6,
            'Monthly Reactive': 4, // Assuming monthly is 4 weeks
            'Credit': 0 // No fixed budget for credit projects
          };
          budgetHours = (appetiteWeeks[appetite] || 0) * 25;
        }

        projectTotals[togglProjectId] = {
          projectId: entry.project_id || null, // Use the actual project ID from our database
          togglProjectId,
          projectName,
          clientName: matchedProject?.clientName || togglProject?.clientName || '-',
          hours: 0,
          budgetHours,
          isUnmatchedEntry,
          appetite,
          notionProjectId // This is the notion_project_id from our projects table
        };
      }
      projectTotals[togglProjectId].hours += hoursSpent;
    });

    return Object.values(projectTotals)
      .sort((a, b) => b.hours - a.hours);
  };

  const processUserTotals = (data) => {
    const userTotals = {};
    
    data.forEach(entry => {
      const userId = Number(entry.toggl_user_id); // Convert to number for consistent comparison
      const hoursSpent = entry.duration / 3600000; // Convert milliseconds to hours
      const userInfo = userMap.get(userId);
      
      if (!userTotals[userId]) {
        userTotals[userId] = {
          userId,
          userName: userInfo?.name || 'Unknown User',
          hours: 0,
          projectCount: new Set()
        };
      }
      userTotals[userId].hours += hoursSpent;
      if (entry.project_id) {
        userTotals[userId].projectCount.add(entry.project_id);
      }
    });

    return Object.values(userTotals)
      .map(user => ({
        ...user,
        projectCount: user.projectCount.size
      }))
      .sort((a, b) => b.hours - a.hours);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-4 border rounded shadow-lg">
          <p className="font-bold">{label} {data.isWeekend ? '(Weekend)' : ''}</p>
          <p className="text-blue-600">Hours: {payload[0].value.toFixed(2)}</p>
        </div>
      );
    }
    return null;
  };

  const handleOpenInNotion = (notionProjectId) => {
    if (!notionProjectId) return;
    // Remove any hyphens from the ID
    const cleanId = notionProjectId.replace(/-/g, '');
    window.open(`https://www.notion.so/inovo/${cleanId}`, '_blank');
  };

  const handleOpenInToggl = (togglProjectId) => {
    if (!togglProjectId) return;
    window.open(`https://track.toggl.com/reports/detailed/3098605/period/thisYear/projects/${togglProjectId}`, '_blank');
  };

  const getInitials = (name) => {
    if (!name || name === 'Unknown User') return '??';
    const names = name.split(' ');
    if (names.length === 1) return names[0].charAt(0).toUpperCase();
    return (names[0].charAt(0) + names[names.length - 1].charAt(0)).toUpperCase();
  };

  // Function to generate a consistent color based on name
  const getAvatarColor = (name) => {
    if (!name || name === 'Unknown User') return 'bg-gray-200';
    
    const colors = [
      'bg-blue-500',
      'bg-green-500',
      'bg-yellow-500',
      'bg-purple-500',
      'bg-pink-500',
      'bg-indigo-500',
      'bg-red-500',
      'bg-teal-500'
    ];
    
    // Simple hash function to get consistent color
    const hash = name.split('').reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    
    return colors[Math.abs(hash) % colors.length];
  };

  return (
    <div className="min-h-screen bg-[#F8F9FC]">
      <div className="container mx-auto px-6 py-4 space-y-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">Time Tracking Dashboard</h1>
          <MonthSelector 
            selectedDate={selectedDate}
            onDateChange={setSelectedDate}
          />
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        {loading ? (
          <div className="flex items-center justify-center h-32">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#307FE2]"></div>
          </div>
        ) : (
          <>
            <StatsBoxes timeData={timeData} />

            {timeData.length === 0 ? (
              <div className="bg-white p-6 rounded-2xl shadow-sm text-center">
                <p className="text-gray-500">No time tracking data found for {selectedDate.toLocaleString('default', { month: 'long', year: 'numeric' })}.</p>
              </div>
            ) : (
              <>
                {/* Time Overview Graph */}
                <div className="bg-white p-6 rounded-2xl shadow-sm">
                  <h2 className="text-xl font-bold mb-2">Time Overview</h2>
                  <div className="h-[300px]">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={timeData} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="date" 
                          tick={{ fontSize: 12, fontWeight: 600 }}
                          dy={10}
                        />
                        <YAxis 
                          label={{ value: 'Hours', angle: -90, position: 'insideLeft' }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar 
                          dataKey="hours" 
                          name="Hours"
                          fill="#307FE2"
                          fillOpacity={0.9}
                          shape={(props) => {
                            const { fill, x, y, width, height, payload } = props;
                            return (
                              <rect
                                x={x}
                                y={y}
                                width={width}
                                height={height}
                                fill={payload.isWeekend ? '#D1D5DB' : '#307FE2'}
                              />
                            );
                          }}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>

                <div className="grid gap-6 md:grid-cols-2">
                  {/* Project Time Totals */}
                  <div className="bg-white p-6 rounded-2xl shadow-sm">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-xl font-bold">Project Time Totals</h2>
                      <div className="flex items-center space-x-2">
                        <label htmlFor="show-unmatched" className="text-sm text-gray-600">
                          Show Unmatched Entries
                        </label>
                        <button
                          onClick={() => setShowUnmatchedEntries(!showUnmatchedEntries)}
                          className="relative inline-block w-10 mr-2 align-middle select-none focus:outline-none"
                          role="switch"
                          aria-checked={showUnmatchedEntries}
                          aria-label="Toggle unmatched entries"
                        >
                          <input
                            type="checkbox"
                            id="show-unmatched"
                            checked={showUnmatchedEntries}
                            onChange={(e) => setShowUnmatchedEntries(e.target.checked)}
                            className="sr-only peer"
                          />
                          <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-[#307FE2] cursor-pointer"></div>
                        </button>
                      </div>
                    </div>
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-64">
                              Project
                            </th>
                            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-10">
                              Links
                            </th>
                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                              Hours
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {projectTotals
                            .filter(project => showUnmatchedEntries || !project.isUnmatchedEntry)
                            .map((project) => (
                            <tr key={project.togglProjectId} className={project.isUnmatchedEntry ? 'bg-gray-50' : ''}>
                              <td className="px-6 py-4">
                                <div className="flex items-center">
                                  <div className="max-w-[280px]">
                                    <div className={`text-sm font-medium ${project.isUnmatchedEntry ? 'text-gray-500 italic' : 'text-gray-900'}`}>
                                      <div className="flex items-start space-x-1">
                                        {project.isUnmatchedEntry && (
                                          <span className="text-xs bg-gray-100 text-gray-600 px-1.5 py-0.5 rounded">No Project</span>
                                        )}
                                        <span className="line-clamp-2" title={project.projectName}>
                                          {project.projectName}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex items-center space-x-2 mt-0.5">
                                      {project.clientName !== '-' && (
                                        <span className="text-xs text-gray-500 truncate max-w-[200px]" title={project.clientName}>
                                          {project.clientName}
                                        </span>
                                      )}
                                      {project.appetite && (
                                        <span className="text-xs px-1.5 py-0.5 bg-blue-50 text-blue-700 rounded-full">
                                          {project.appetite}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-center">
                                <div className="flex items-center justify-center space-x-2">
                                  {!project.isUnmatchedEntry && project.notionProjectId && (
                                    <button
                                      onClick={() => handleOpenInNotion(project.notionProjectId)}
                                      className="p-1 hover:bg-gray-100 rounded-md transition-colors group inline-flex"
                                      title="Open in Notion"
                                    >
                                      <NotionIcon className="h-4 w-4 text-gray-500 group-hover:text-gray-700" />
                                    </button>
                                  )}
                                  {project.togglProjectId && (
                                    <button
                                      onClick={() => handleOpenInToggl(project.togglProjectId)}
                                      className="p-1 hover:bg-gray-100 rounded-md transition-colors group inline-flex"
                                      title="Open in Toggl"
                                    >
                                      <TogglIcon className="h-4 w-4 text-[#E57CD8] group-hover:text-[#E57CD8] fill-current" />
                                    </button>
                                  )}
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-right">
                                <div className="font-medium">
                                  <span className={`${
                                    project.budgetHours ? (
                                      project.hours > project.budgetHours 
                                        ? 'bg-red-50 text-red-700' 
                                        : 'bg-green-50 text-green-700'
                                    ) : 'text-gray-900'
                                  } px-2 py-1 rounded`}>
                                    {project.hours.toFixed(1)}
                                  </span>
                                  {project.budgetHours > 0 && (
                                    <span className="text-gray-500 ml-1">/ {project.budgetHours}</span>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* User Time Totals */}
                  <div className="bg-white p-6 rounded-2xl shadow-sm">
                    <h2 className="text-xl font-bold mb-4">Team Member Time Totals</h2>
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Team Member
                            </th>
                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Hours
                            </th>
                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Projects
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {userTotals.map((user) => (
                            <tr key={user.userId}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center space-x-3">
                                  <div className={`flex-shrink-0 h-8 w-8 rounded-full ${getAvatarColor(user.userName)} flex items-center justify-center`}>
                                    <span className="text-sm font-medium text-white">
                                      {getInitials(user.userName)}
                                    </span>
                                  </div>
                                  <div className="flex items-center">
                                    <div className="text-sm font-medium text-gray-900">
                                      {user.userName}
                                    </div>
                                    {!userMap.has(user.userId) && (
                                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                                        Unlinked
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                                {user.hours.toFixed(2)}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                                {user.projectCount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TimeTracking; 