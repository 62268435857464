import React, { useState, useMemo } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ActionDropdown } from './ActionDropdown';
import TransactionModal from './TransactionModal';
import { Button } from "../ui/button"

const TransactionTable = ({
  title,
  description,
  transactions,
  newTransaction,
  setNewTransaction,
  isAdding,
  editingTransaction,
  setEditingTransaction,
  handleAdd,
  handleUpdate,
  handleEditStart,
  handleEditCancel,
  handleDelete,
  handleDuplicate,
  getRowClassName,
  type,
  isSaving,
  columns,
  className = "",
  ProjectSelector,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleOpenAddModal = () => {
    setIsModalOpen(true);
  };

  const handleOpenEditModal = (id) => {
    handleEditStart(id);
    setIsEditModalOpen(true);
  };

  const handleRowClick = (e, transaction) => {
    // If the click is coming from within the actions column, don't open edit modal
    if (e.target.closest('td:last-child')) {
      return;
    }

    // If this is a future recurring transaction, find and edit the original transaction
    const transactionIdToEdit = transaction.is_future_recurring 
      ? transaction.original_transaction_id 
      : transaction.id;

    handleOpenEditModal(transactionIdToEdit);
  };

  const getRowOpacity = (date) => {
    const transactionDate = new Date(date);
    const today = new Date();
    // Reset time part for accurate date comparison
    today.setHours(0, 0, 0, 0);
    transactionDate.setHours(0, 0, 0, 0);
    return transactionDate > today ? 'opacity-50' : '';
  };

  // Helper function to get recurring tooltip content
  const getRecurringTooltip = (transaction) => {
    if (!transaction.is_recurring) return '';

    const frequency = transaction.recurring_frequency.charAt(0).toUpperCase() + 
                     transaction.recurring_frequency.slice(1);
    const endDate = transaction.recurring_end_date 
      ? ` until ${new Date(transaction.recurring_end_date).toLocaleDateString()}`
      : ' (no end date)';

    return `Recurring ${frequency.toLowerCase()}${endDate}`;
  };

  return (
    <div className={`p-6 ${className}`}>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h2 className="text-xl font-bold">{title}</h2>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
        <Button 
          onClick={handleOpenAddModal} 
          className={`flex items-center gap-2 rounded-xl ${
            type === 'income' 
              ? 'bg-green-50 hover:bg-green-100 text-green-600 border border-green-200' 
              : 'bg-red-50 hover:bg-red-100 text-red-600 border border-red-200'
          }`}
        >
          <PlusIcon className="h-4 w-4" />
          Add {type === 'income' ? 'Income' : 'Expense'}
        </Button>
      </div>

      <div className="overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="flex w-full">
              {columns.map((column, index) => (
                <th 
                  key={index} 
                  className={`text-left text-xs font-semibold text-gray-600 uppercase tracking-wider py-3 px-4 ${column.width}`}
                >
                  {column.header}
                </th>
              ))}
              <th className="flex-none w-12" aria-label="Actions">
                {/* Empty header for actions column */}
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => {
              const isRecurring = transaction.is_recurring;
              const isFutureRecurring = transaction.is_future_recurring;
              const recurringTooltip = getRecurringTooltip(transaction);
              
              return (
                <tr 
                  key={transaction.id} 
                  className={`flex w-full ${getRowClassName(transaction.id)} cursor-pointer hover:bg-gray-50 ${
                    isFutureRecurring ? 'opacity-50' : ''
                  }`}
                  onClick={(e) => handleRowClick(e, transaction)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      handleRowClick(e, transaction);
                    }
                  }}
                >
                  {columns.map((column, index) => (
                    <td key={index} className={`py-4 px-4 ${column.width}`}>
                      <div>
                        {column.cell(transaction)}
                        {isRecurring && index === 0 && !isFutureRecurring && (
                          <div 
                            className="text-xs text-indigo-600 mt-1"
                            title={recurringTooltip}
                          >
                            ↻
                          </div>
                        )}
                        {isFutureRecurring && index === 0 && (
                          <div className="text-xs text-gray-500 mt-1">
                            Recurring
                          </div>
                        )}
                      </div>
                    </td>
                  ))}
                  <td 
                    className={`py-4 px-4 flex-none w-12 ${getRowOpacity(transaction.date)}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {!isFutureRecurring && (
                      <ActionDropdown
                        transaction={transaction}
                        handleEdit={() => handleOpenEditModal(transaction.id)}
                        handleDelete={handleDelete}
                        handleDuplicate={handleDuplicate}
                        type={type}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <TransactionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Add ${type === 'income' ? 'Income' : 'Expense'}`}
        transaction={newTransaction}
        setTransaction={setNewTransaction}
        handleSave={() => {
          handleAdd();
          setIsModalOpen(false);
        }}
        isSaving={isAdding}
        type={type}
        ProjectSelector={ProjectSelector}
      />

      <TransactionModal
        isOpen={isEditModalOpen}
        onClose={() => {
          handleEditCancel();
          setIsEditModalOpen(false);
        }}
        title={`Edit ${type === 'income' ? 'Income' : 'Expense'}`}
        transaction={editingTransaction}
        setTransaction={setEditingTransaction}
        handleSave={() => {
          handleUpdate();
          setIsEditModalOpen(false);
        }}
        isSaving={isSaving}
        type={type}
        ProjectSelector={ProjectSelector}
      />
    </div>
  );
};

export default TransactionTable; 