import React from 'react';
import { ClockIcon, BriefcaseIcon, UsersIcon } from '@heroicons/react/24/outline';

const StatsBoxes = ({ timeData }) => {
  // Calculate total hours for the month
  const totalHours = timeData.reduce((total, entry) => total + entry.hours, 0);
  
  // Calculate average daily hours
  const averageDailyHours = timeData.length > 0 ? totalHours / timeData.length : 0;
  
  // Calculate number of active projects
  const activeProjects = new Set(timeData.map(entry => entry.projectId)).size;

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div className="bg-white p-6 rounded-2xl shadow-sm">
        <div className="flex items-center">
          <div className="p-2 bg-blue-100 rounded-lg">
            <ClockIcon className="h-6 w-6 text-[#307FE2]" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-600">Total Hours</p>
            <p className="text-2xl font-semibold text-gray-900">{totalHours.toFixed(1)}</p>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-2xl shadow-sm">
        <div className="flex items-center">
          <div className="p-2 bg-blue-100 rounded-lg">
            <BriefcaseIcon className="h-6 w-6 text-[#307FE2]" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-600">Active Projects</p>
            <p className="text-2xl font-semibold text-gray-900">{activeProjects}</p>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-2xl shadow-sm">
        <div className="flex items-center">
          <div className="p-2 bg-blue-100 rounded-lg">
            <UsersIcon className="h-6 w-6 text-[#307FE2]" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-600">Avg Daily Hours</p>
            <p className="text-2xl font-semibold text-gray-900">{averageDailyHours.toFixed(1)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsBoxes; 