import React from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { formatCurrency } from '../../utils/formatters';

const StatsBox = ({ title, amount, trend }) => (
  <div className="bg-white p-6 rounded-lg shadow">
    <div className="flex flex-row items-center justify-between space-y-0 pb-2">
      <h3 className="text-sm font-medium">{title}</h3>
      <CalendarIcon className="h-4 w-4 text-muted-foreground" />
    </div>
    <div className={`text-2xl font-bold ${amount < 0 ? 'text-red-600' : ''}`}>
      {formatCurrency(amount)}
    </div>
    {trend !== undefined && (
      <div className={`text-sm mt-1 flex items-center ${
        trend > 0 ? 'text-green-600' : trend < 0 ? 'text-red-600' : 'text-gray-500'
      }`}>
        {trend > 0 ? '↑' : trend < 0 ? '↓' : '→'} {Math.abs(trend)}% vs last month
      </div>
    )}
  </div>
);

const StatsBoxes = ({ transactions, selectedDate }) => {
  const calculateMonthlyStats = () => {
    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    const lastMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
    const lastMonthYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;

    // Initialize stats objects
    const selectedMonthStats = { income: 0, expenses: 0, balance: 0 };
    const lastMonthStats = { income: 0, expenses: 0, balance: 0 };

    transactions.forEach(transaction => {
      // Force interpretation as local time by appending noon time
      const transactionDate = new Date(transaction.date + 'T12:00:00');
      const transactionYear = transactionDate.getFullYear();
      const transactionMonth = transactionDate.getMonth();
      const amount = Number(transaction.amount) || 0; // Ensure amount is a number

      // Selected month transactions
      if (transactionYear === selectedYear && transactionMonth === selectedMonth) {
        if (transaction.payment_type === 'credit') {
          selectedMonthStats.income += amount;
        } else if (transaction.payment_type === 'debit') {
          selectedMonthStats.expenses += amount;
        }
      }
      // Last month transactions
      else if (transactionYear === lastMonthYear && transactionMonth === lastMonth) {
        if (transaction.payment_type === 'credit') {
          lastMonthStats.income += amount;
        } else if (transaction.payment_type === 'debit') {
          lastMonthStats.expenses += amount;
        }
      }
    });

    // Calculate balances
    selectedMonthStats.balance = selectedMonthStats.income - selectedMonthStats.expenses;
    lastMonthStats.balance = lastMonthStats.income - lastMonthStats.expenses;

    // Calculate trends (percentage change from last month)
    const calculateTrend = (current, last) => {
      if (last === 0) return current > 0 ? 100 : 0;
      return Math.round(((current - last) / Math.abs(last)) * 100);
    };

    return {
      current: selectedMonthStats,
      trends: {
        income: calculateTrend(selectedMonthStats.income, lastMonthStats.income),
        expenses: calculateTrend(selectedMonthStats.expenses, lastMonthStats.expenses),
        balance: calculateTrend(selectedMonthStats.balance, lastMonthStats.balance)
      }
    };
  };

  const { current, trends } = calculateMonthlyStats();

  // Format the month name for the title
  const monthName = selectedDate.toLocaleString('default', { month: 'long' });

  return (
    <div className="grid gap-6 md:grid-cols-3">
      <div className="bg-white p-6 rounded-2xl shadow-sm">
        <h3 className="text-gray-500 text-sm font-medium mb-2">Total Income</h3>
        <div className="space-y-2">
          <p className="text-3xl font-bold">${current.income.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
          <div className="flex items-center">
            <span className={`inline-flex items-center px-2 py-0.5 rounded text-sm ${
              trends.income >= 0 
                ? 'text-green-800 bg-green-100' 
                : 'text-red-800 bg-red-100'
            }`}>
              {trends.income >= 0 ? '↑' : '↓'} {Math.abs(Math.round(trends.income))}%
            </span>
            <span className="ml-2 text-gray-500 text-sm">Compared to last month</span>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-2xl shadow-sm">
        <h3 className="text-gray-500 text-sm font-medium mb-2">Total Expenses</h3>
        <div className="space-y-2">
          <p className="text-3xl font-bold">${current.expenses.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
          <div className="flex items-center">
            <span className={`inline-flex items-center px-2 py-0.5 rounded text-sm ${
              trends.expenses <= 0 
                ? 'text-green-800 bg-green-100' 
                : 'text-red-800 bg-red-100'
            }`}>
              {trends.expenses <= 0 ? '↓' : '↑'} {Math.abs(Math.round(trends.expenses))}%
            </span>
            <span className="ml-2 text-gray-500 text-sm">Compared to last month</span>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-2xl shadow-sm">
        <h3 className="text-gray-500 text-sm font-medium mb-2">Profit</h3>
        <div className="space-y-2">
          <p className="text-3xl font-bold">${current.balance.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
          <div className="flex items-center">
            <span className={`inline-flex items-center px-2 py-0.5 rounded text-sm ${
              trends.balance >= 0 
                ? 'text-green-800 bg-green-100' 
                : 'text-red-800 bg-red-100'
            }`}>
              {trends.balance >= 0 ? '↑' : '↓'} {Math.abs(Math.round(trends.balance))}%
            </span>
            <span className="ml-2 text-gray-500 text-sm">Compared to last month</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsBoxes; 