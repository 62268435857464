import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';
import { XMarkIcon, PlusIcon } from '@heroicons/react/24/solid';
import TogglUserSelect from '../components/TogglUserSelect';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editedUser, setEditedUser] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [company, setCompany] = useState('');
  const [rate, setRate] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    fetchUsers();
    fetchCurrentUser();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .order('name');
      if (error) throw error;
      setUsers(data || []);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users. Please try again.');
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrentUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data } = await supabase
        .from('users')
        .select('*')
        .eq('auth_id', user.id)
        .single();
      setCurrentUser(data);
    }
  };

  const handleRowClick = (user) => {
    setSelectedUser(user);
    setEditedUser({ ...user });
    setCompany(user.company || '');
    setRate(user.rate || '');
    setError(null);
    setSuccessMessage(null);
    setIsNewUser(false);
  };

  const handleNewUser = () => {
    setSelectedUser({});
    setEditedUser({ email: '', name: '', role: 'pending' });
    setCompany('');
    setRate('');
    setError(null);
    setSuccessMessage(null);
    setIsNewUser(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser(prev => ({ ...prev, [name]: value }));
    if (name === 'company') setCompany(value);
    if (name === 'rate') setRate(value);
  };

  const handleSave = async () => {
    try {
      setError(null);
      setSuccessMessage(null);

      const userData = {
        name: editedUser.name,
        email: editedUser.email,
        role: editedUser.role || 'pending',
        company: company || null,
        rate: rate ? parseFloat(rate) : null,
        toggl_user_id: editedUser.toggl_user_id,
        updated_at: new Date().toISOString()
      };

      // Remove undefined or null values
      Object.keys(userData).forEach(key => 
        (userData[key] === undefined || userData[key] === null) && delete userData[key]
      );

      let result;
      if (isNewUser) {
        result = await supabase
          .from('users')
          .insert([userData])
          .select('*')
          .single();
      } else {
        result = await supabase
          .from('users')
          .update(userData)
          .eq('id', editedUser.id)
          .select('*')
          .single();
      }

      if (result.error) throw result.error;

      setSuccessMessage(isNewUser ? 'User created successfully' : 'User updated successfully');
      
      setSelectedUser(result.data);
      setEditedUser(result.data);
      await fetchUsers(); // Refresh the user list

      setTimeout(() => {
        setSuccessMessage(null);
        if (isNewUser) {
          setSelectedUser(null);
        }
      }, 2000);

    } catch (error) {
      console.error('Error in handleSave:', error);
      setError(`Failed to ${isNewUser ? 'create' : 'update'} user: ${error.message}`);
    }
  };

  if (loading) return <div className="p-4">Loading users...</div>;

  return (
    <div className="flex h-screen overflow-hidden">
      <div className="flex-grow overflow-auto">
        <div className="container px-4 py-8">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold">Users</h1>
            <button
              onClick={handleNewUser}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
            >
              <PlusIcon className="h-5 w-5 inline-block mr-2" />
              Add New User
            </button>
          </div>
        </div>

        <div className="container mx-auto px-4 py-8">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="w-full text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Toggl</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map((user) => (
                  <tr key={user.id} onClick={() => handleRowClick(user)} className="hover:bg-gray-100 cursor-pointer">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {user.name || 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.role || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.toggl_user_id ? (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          Connected
                        </span>
                      ) : (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          Not Connected
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {selectedUser && (
        <div className="w-1/3 bg-white border-l border-gray-200 overflow-y-auto">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold text-gray-800">{isNewUser ? 'Add New User' : 'Edit User'}</h2>
              <button onClick={() => setSelectedUser(null)} className="text-gray-500 hover:text-gray-700">
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            {error && <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">{error}</div>}
            {successMessage && <div className="mb-4 p-3 bg-green-100 text-green-700 rounded-md">{successMessage}</div>}
            <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
              <div className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={editedUser?.name || ''}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-4 py-3 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-base"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={editedUser?.email || ''}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-4 py-3 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-base"
                  />
                </div>
                <div>
                  <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-1">Role</label>
                  <select
                    id="role"
                    name="role"
                    value={editedUser?.role || ''}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-4 py-3 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-base"
                  >
                    <option value="">Select a role</option>
                    <option value="pending">Pending</option>
                    <option value="client">Client</option>
                    <option value="team">Team</option>
                    <option value="admin">Admin</option>
                    <option value="banned">Banned</option>
                  </select>
                </div>

                {editedUser?.role === 'team' && (
                  <>
                    <div>
                      <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">Company</label>
                      <input
                        type="text"
                        name="company"
                        id="company"
                        value={company}
                        onChange={handleInputChange}
                        className="mt-1 block w-full px-4 py-3 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="rate" className="block text-sm font-medium text-gray-700 mb-1">Rate ($/hr)</label>
                      <input
                        type="text"
                        name="rate"
                        id="rate"
                        value={rate}
                        onChange={handleInputChange}
                        className="mt-1 block w-full px-4 py-3 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-base"
                      />
                    </div>
                  </>
                )}

                {currentUser?.role === 'admin' && !isNewUser && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Toggl User</label>
                    <TogglUserSelect
                      userId={editedUser.id}
                      currentTogglId={editedUser.toggl_user_id}
                      onSelect={(togglId) => {
                        // Only update the local state, don't save to database yet
                        setEditedUser(prev => ({ ...prev, toggl_user_id: togglId }));
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="mt-8 flex items-center justify-end">
                <button
                  type="submit"
                  className="px-6 py-3 bg-indigo-600 text-white text-base font-semibold rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                >
                  {isNewUser ? 'Create User' : 'Save Changes'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;