import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ChevronDownIcon, CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { createPortal } from 'react-dom';
import togglService from '../services/togglService';

const TogglIcon = ({ className }) => (
  <svg 
    className={className} 
    viewBox="0 0 24 24" 
    role="img" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 0C5.373 0 0 5.37 0 12s5.373 12 12 12c6.63 0 12-5.37 12-12S18.63 0 12 0zm-.84 4.67h1.68v8.36h-1.68V4.67zM12 18.155c-3.24-.002-5.865-2.63-5.864-5.868 0-2.64 1.767-4.956 4.314-5.655v1.71c-1.628.64-2.698 2.21-2.695 3.96 0 2.345 1.903 4.244 4.248 4.243 2.344-.002 4.244-1.903 4.243-4.248 0-1.745-1.07-3.312-2.694-3.95V6.63c2.55.7 4.314 3.018 4.314 5.66 0 3.24-2.626 5.864-5.865 5.864z"/>
  </svg>
);

const CACHE_KEY = 'toggl_users_cache';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour

const useTogglUsers = () => {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  const fetchUsers = async (forceRefresh = false) => {
    if (!forceRefresh) {
      if (users) return users;

      try {
        const cached = localStorage.getItem(CACHE_KEY);
        if (cached) {
          const { data, timestamp } = JSON.parse(cached);
          if (Date.now() - timestamp < CACHE_DURATION) {
            setUsers(data);
            return data;
          }
        }
      } catch (err) {
        console.error('Cache read error:', err);
      }
    }

    try {
      setLoading(true);
      setError(null);
      
      const data = await togglService.getTogglUsers();
      
      localStorage.setItem(CACHE_KEY, JSON.stringify({
        data,
        timestamp: Date.now()
      }));
      
      setUsers(data);
      setRetryCount(0);
      return data;
    } catch (err) {
      setError(err.message);
      
      if (retryCount < maxRetries) {
        setRetryCount(prev => prev + 1);
        const backoffDelay = Math.pow(2, retryCount + 1) * 1000;
        setTimeout(() => {
          fetchUsers(true);
        }, backoffDelay);
      }
      
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { users, loading, error, fetchUsers, retryCount, maxRetries };
};

const TogglUserSelect = ({ userId, currentTogglId, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(currentTogglId ? { id: currentTogglId } : null);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);
  const [error, setError] = useState(null);

  const { 
    users: togglUsers, 
    loading, 
    error: fetchError,
    retryCount,
    maxRetries,
    fetchUsers 
  } = useTogglUsers();

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutsideTrigger = dropdownRef.current && !dropdownRef.current.contains(event.target);
      const isOutsidePortal = !event.target.closest('.toggl-user-dropdown-portal');
      
      if (isOutsideTrigger && isOutsidePortal) {
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !togglUsers) {
      fetchUsers().catch(err => {
        setError(err.message);
      });
    }
  }, [isOpen, togglUsers, fetchUsers]);

  useEffect(() => {
    if (togglUsers && currentTogglId) {
      const currentUser = togglUsers.find(user => user.id === currentTogglId);
      if (currentUser) {
        setSelectedUser(currentUser);
      }
    }
  }, [togglUsers, currentTogglId]);

  const filteredUsers = useMemo(() => {
    if (!togglUsers) return [];
    
    const searchLower = searchTerm.toLowerCase();
    return togglUsers.filter(user => 
      user.fullname?.toLowerCase().includes(searchLower) ||
      user.email?.toLowerCase().includes(searchLower)
    );
  }, [togglUsers, searchTerm]);

  const handleSelect = (togglUser) => {
    if (!togglUser || !togglUser.id) return;
    
    setSelectedUser(togglUser);
    onSelect(togglUser.id);
    setIsOpen(false);
    setSearchTerm('');
  };

  const handleDisconnect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedUser(null);
    onSelect(null);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {selectedUser || currentTogglId ? (
        <div className="flex items-center space-x-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
            className="inline-flex items-center px-4 py-2 text-sm bg-green-50 border border-green-300 text-green-700 rounded-md hover:bg-green-100 group"
          >
            <CheckCircleIcon className="w-5 h-5 mr-2 text-green-500 flex-shrink-0" />
            <div className="flex flex-col items-start overflow-hidden">
              <span className="truncate">Connected to Toggl</span>
              {selectedUser?.fullname && (
                <span className="text-xs text-green-600 truncate">
                  {selectedUser.fullname}
                  {selectedUser.email && ` (${selectedUser.email})`}
                </span>
              )}
            </div>
            <ChevronDownIcon className="w-4 h-4 ml-2 flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity" />
          </button>
          <button
            onClick={handleDisconnect}
            className="p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-md transition-colors"
            title="Disconnect Toggl user"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
      ) : (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          className="inline-flex justify-between items-center w-64 px-4 py-2 text-sm bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          disabled={loading}
        >
          {loading ? (
            <div className="flex items-center">
              <div className="animate-spin h-4 w-4 mr-2 border-2 border-gray-500 border-t-transparent rounded-full"></div>
              Loading...
              {retryCount > 0 && ` (Retry ${retryCount}/${maxRetries})`}
            </div>
          ) : (
            <>
              <span>Select Toggl User</span>
              <ChevronDownIcon className="w-4 h-4 ml-2 flex-shrink-0" />
            </>
          )}
        </button>
      )}

      {isOpen && createPortal(
        <div 
          className="fixed bg-white rounded-md shadow-lg toggl-user-dropdown-portal"
          style={{
            width: '24rem',
            maxHeight: '24rem',
            top: dropdownRef.current?.getBoundingClientRect().bottom + 8 || 0,
            left: dropdownRef.current?.getBoundingClientRect().left || 0,
            zIndex: 50
          }}
        >
          <div className="sticky top-0 p-2 border-b border-gray-200 bg-white">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search users..."
              className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              autoFocus
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          
          <div className="overflow-y-auto" style={{ maxHeight: '20rem' }}>
            {loading ? (
              <div className="p-4 text-center text-gray-500">
                <div className="animate-spin h-5 w-5 mx-auto mb-2 border-2 border-gray-500 border-t-transparent rounded-full"></div>
                Loading users...
                {retryCount > 0 && (
                  <div className="text-xs text-gray-400 mt-1">
                    Retry attempt {retryCount}/{maxRetries}
                  </div>
                )}
              </div>
            ) : error || fetchError ? (
              <div className="p-4 text-center">
                <div className="text-red-500 mb-2">{error || fetchError}</div>
                <button
                  onClick={() => fetchUsers(true)}
                  className="text-sm text-blue-500 hover:text-blue-600"
                >
                  Try Again
                </button>
              </div>
            ) : filteredUsers.length === 0 ? (
              <div className="p-4 text-center text-gray-500">
                {searchTerm ? 'No matching users' : 'No users available'}
              </div>
            ) : (
              filteredUsers.map((user) => (
                <div
                  key={user.id}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSelect(user);
                  }}
                >
                  <div className="text-sm font-medium text-gray-900">{user.fullname || user.email}</div>
                  {user.email && user.fullname && (
                    <div className="text-xs text-gray-500">{user.email}</div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

export default TogglUserSelect; 