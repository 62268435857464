import React from 'react';

const NotionIcon = ({ className = "h-4 w-4", color = "currentColor" }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.459 4.208c.746.606 1.026.56 2.428.466l13.215-.793c.28 0 .047-.28-.046-.326L17.86 2.767c-.42-.326-.981-.7-2.055-.607L3.01 3.549c-.466.046-.56.28-.42.466l1.869.793zm.793 2.103v13.284c0 .746.373 1.027 1.214.98l14.523-.84c.841-.046.935-.56.935-1.167V5.062c0-.606-.233-.886-.748-.833l-15.177.886c-.56.047-.747.327-.747.886zm14.337.745c.093.42 0 .84-.42.888l-.7.14v10.264c-.608.327-1.168.514-1.635.514-.748 0-.935-.234-1.495-.933l-4.577-7.186v6.952L12.21 19s0 .84-1.168.84l-3.222.186c-.093-.186 0-.653.327-.746l.84-.233V9.854L7.822 9.62c-.094-.42.14-1.026.793-1.073l3.456-.233 4.764 7.279v-6.44l-1.215-.139c-.093-.514.28-.887.747-.933zM1.936 1.035l13.31-.98c1.634-.14 2.055-.047 3.082.7l4.249 2.986c.7.513.934.653.934 1.213v16.378c0 1.026-.373 1.634-1.68 1.726l-15.458.934c-.98.047-1.448-.093-1.962-.747l-2.986-3.874C.326 18.532 0 17.927 0 17.274V2.8c0-.839.374-1.58 1.936-1.766z"
      fill={color}
    />
  </svg>
);

export default NotionIcon; 