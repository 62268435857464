import { supabase } from '../utils/supabaseClient';

// Helper function to handle API errors
const handleApiError = (error) => {
  console.error('Toggl API error:', error);
  
  if (error.code === 'ECONNABORTED') {
    throw new Error('Request timed out. Please try again.');
  }

  const errorMessage = error.response?.data?.error || error.response?.data?.details || error.message;
  throw new Error(errorMessage || 'An unexpected error occurred');
};

// Helper function to make API requests with consistent error handling
const makeApiRequest = async (endpoint, options = {}) => {
  try {
    const response = await fetch(endpoint, {
      ...options,
      headers: {
        ...options.headers,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      }
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || error.details || 'Request failed');
    }

    return await response.json();
  } catch (error) {
    handleApiError(error);
  }
};

const togglService = {
  // Fetch all workspace users from Toggl
  async getTogglUsers() {
    return makeApiRequest('/api/toggl/users');
  },

  // Update user's Toggl connection
  async updateTogglConnection(userId, togglData) {
    try {
      // First verify the user exists
      const { data: existingUser, error: fetchError } = await supabase
        .from('users')
        .select()
        .eq('id', userId)
        .single();

      if (fetchError) throw fetchError;
      if (!existingUser) throw new Error('User not found');

      // Then perform the update
      const { data, error } = await supabase
        .from('users')
        .update({ 
          toggl_user_id: togglData.id,
          updated_at: new Date().toISOString()
        })
        .eq('id', userId)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error updating Toggl connection:', error);
      throw error;
    }
  },

  // Remove user's Toggl connection
  async removeTogglConnection(userId) {
    try {
      // First verify the user exists
      const { data: existingUser, error: fetchError } = await supabase
        .from('users')
        .select()
        .eq('id', userId)
        .single();

      if (fetchError) throw fetchError;
      if (!existingUser) throw new Error('User not found');

      // Then perform the update
      const { data, error } = await supabase
        .from('users')
        .update({ 
          toggl_user_id: null,
          updated_at: new Date().toISOString()
        })
        .eq('id', userId)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error removing Toggl connection:', error);
      throw error;
    }
  },

  // Fetch all clients from Toggl
  async getClients() {
    return makeApiRequest('/api/toggl/clients');
  },

  // Create a new client in Toggl
  async createClient(clientName) {
    return makeApiRequest('/api/toggl/clients', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name: clientName })
    });
  },

  // Create a new project in Toggl with client
  async createProject(projectName, clientId = null) {
    return makeApiRequest('/api/toggl/projects', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: projectName,
        clientId
      })
    });
  }
};

export default togglService; 