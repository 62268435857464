import React, { useState, useEffect, useRef, useMemo } from 'react';
import projectConnectionService from '../services/projectConnectionService';
import Loader from '../components/Loader';
import FilterDropdown from '../components/FilterDropdown';
import { ChevronDownIcon, CheckCircleIcon, XMarkIcon, MagnifyingGlassIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import { supabase } from '../utils/supabaseClient';
import { createPortal } from 'react-dom';
import NotionIcon from '../components/NotionIcon';
import { formatDistanceToNow } from 'date-fns';
import togglService from '../services/togglService';

const TogglIcon = ({ className }) => (
  <svg 
    className={className} 
    viewBox="0 0 24 24" 
    role="img" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 0C5.373 0 0 5.37 0 12s5.373 12 12 12c6.63 0 12-5.37 12-12S18.63 0 12 0zm-.84 4.67h1.68v8.36h-1.68V4.67zM12 18.155c-3.24-.002-5.865-2.63-5.864-5.868 0-2.64 1.767-4.956 4.314-5.655v1.71c-1.628.64-2.698 2.21-2.695 3.96 0 2.345 1.903 4.244 4.248 4.243 2.344-.002 4.244-1.903 4.243-4.248 0-1.745-1.07-3.312-2.694-3.95V6.63c2.55.7 4.314 3.018 4.314 5.66 0 3.24-2.626 5.864-5.865 5.864z"/>
  </svg>
);

const PROJECTS_CACHE_KEY = 'toggl_projects_cache';
const CLIENTS_CACHE_KEY = 'toggl_clients_cache';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes instead of 1 hour

const STATUS_ORDER = [
  'Inbox',
  'Shaping',
  'Dev Ready',
  'In Progress',
  'In Review',
  'Completed',
  'Blocked'
];

const useTogglProjects = () => {
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  // Check cache and fetch data
  const fetchProjects = async (forceRefresh = false) => {
    if (!forceRefresh) {
      try {
        const cached = localStorage.getItem(PROJECTS_CACHE_KEY);
        if (cached) {
          const { data, timestamp } = JSON.parse(cached);
          if (Date.now() - timestamp < CACHE_DURATION) {
            console.log(`Using cached Toggl projects (${data.length} projects)`);
            setProjects(data);
            return data;
          } else {
            console.log('Cache expired, fetching fresh data...');
          }
        }
      } catch (err) {
        console.warn('Cache read error:', err);
      }
    }

    try {
      setLoading(true);
      setError(null);
      
      console.log('Fetching Toggl projects...');
      const response = await fetch('/api/toggl/projects', {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Toggl projects fetch error:', {
          status: response.status,
          statusText: response.statusText,
          errorData
        });
        throw new Error(errorData.details || errorData.error || 'Failed to fetch Toggl projects');
      }
      
      const data = await response.json();
      console.log(`Received ${data.length} Toggl projects`);
      
      // Update cache
      try {
        localStorage.setItem(PROJECTS_CACHE_KEY, JSON.stringify({
          data,
          timestamp: Date.now()
        }));
      } catch (cacheError) {
        console.warn('Failed to cache Toggl projects:', cacheError);
      }
      
      setProjects(data);
      setRetryCount(0);
      return data;
    } catch (err) {
      console.error('Error in fetchProjects:', err);
      setError(err.message);
      
      if (err.response?.status === 504 && retryCount < maxRetries) {
        setRetryCount(prev => prev + 1);
        const backoffDelay = Math.pow(2, retryCount + 1) * 1000;
        console.log(`Retrying in ${backoffDelay/1000}s... (Attempt ${retryCount + 1}/${maxRetries})`);
        setTimeout(() => fetchProjects(true), backoffDelay);
      }
      
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { projects, loading, error, fetchProjects, retryCount, maxRetries };
};

const useTogglClients = () => {
  const [clients, setClients] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  const fetchClients = async (forceRefresh = false) => {
    if (!forceRefresh) {
      // Try to get from memory first
      if (clients) return clients;

      // Try to get from localStorage
      try {
        const cached = localStorage.getItem(CLIENTS_CACHE_KEY);
        if (cached) {
          const { data, timestamp } = JSON.parse(cached);
          if (Date.now() - timestamp < CACHE_DURATION) {
            setClients(data);
            return data;
          }
        }
      } catch (err) {
        console.error('Cache read error:', err);
      }
    }

    try {
      setLoading(true);
      setError(null);
      const data = await togglService.getClients();
      
      // Update cache
      localStorage.setItem(CLIENTS_CACHE_KEY, JSON.stringify({
        data,
        timestamp: Date.now()
      }));
      
      setClients(data);
      setRetryCount(0); // Reset retry count on success
      return data;
    } catch (err) {
      setError(err.message);
      
      // Implement retry logic for 504 errors
      if (retryCount < maxRetries) {
        setRetryCount(prev => prev + 1);
        const backoffDelay = Math.pow(2, retryCount + 1) * 1000;
        setTimeout(() => {
          fetchClients(true);
        }, backoffDelay);
      }
      
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { clients, loading, error, fetchClients, retryCount, maxRetries };
};

const TogglProjectSelect = ({ projectId, currentTogglId, onSelect, projectTitle, clientName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const { 
    projects: togglProjects, 
    loading: projectsLoading, 
    error: projectsError,
    retryCount,
    maxRetries,
    fetchProjects 
  } = useTogglProjects();

  const {
    clients: togglClients,
    loading: clientsLoading,
    error: clientsError,
    fetchClients
  } = useTogglClients();

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside both the trigger button and the portal content
      const isOutsideTrigger = dropdownRef.current && !dropdownRef.current.contains(event.target);
      const isOutsidePortal = !event.target.closest('.toggl-project-dropdown-portal');
      
      if (isOutsideTrigger && isOutsidePortal) {
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Fetch projects when dropdown opens
  useEffect(() => {
    if (isOpen && !togglProjects) {
      fetchProjects().catch(err => {
        setSaveError(err.message);
      });
    }
  }, [isOpen, togglProjects, fetchProjects]);

  // Fetch clients when dropdown opens
  useEffect(() => {
    if (isOpen && !togglClients) {
      fetchClients().catch(err => {
        setSaveError(err.message);
      });
    }
  }, [isOpen, togglClients, fetchClients]);

  // Find matching client by name
  useEffect(() => {
    if (togglClients && clientName && !selectedClient) {
      const matchingClient = togglClients.find(
        client => client.name.toLowerCase() === clientName.toLowerCase()
      );
      if (matchingClient) {
        setSelectedClient(matchingClient);
      }
    }
  }, [togglClients, clientName, selectedClient]);

  // Filter projects based on search term with error handling
  const filteredProjects = useMemo(() => {
    if (!togglProjects) return [];
    
    try {
      const searchLower = searchTerm.toLowerCase().trim();
      return togglProjects.filter(project => {
        try {
          return (
            (project.name || '').toLowerCase().includes(searchLower) ||
            (project.client_name || '').toLowerCase().includes(searchLower)
          );
        } catch (err) {
          console.warn('Error filtering project:', err, project);
          return false;
        }
      });
    } catch (err) {
      console.error('Error in project filtering:', err);
      return [];
    }
  }, [togglProjects, searchTerm]);

  const handleSelect = async (togglProject) => {
    try {
      setIsSaving(true);
      setSaveError(null);
      await projectConnectionService.updateTogglConnection(projectId, togglProject);
      setSelectedProject(togglProject);
      onSelect(togglProject.id, togglProject.name);
      setIsOpen(false);
      setSearchTerm('');
    } catch (err) {
      console.error('Error saving Toggl connection:', err);
      setSaveError(err.message);
      // Keep the dropdown open on error
      setTimeout(() => setSaveError(null), 5000); // Clear error after 5 seconds
    } finally {
      setIsSaving(false);
    }
  };

  const handleDisconnect = async (e) => {
    e.stopPropagation();
    try {
      setIsSaving(true);
      await projectConnectionService.removeTogglConnection(projectId);
      setSelectedProject(null);
      onSelect(null, null);
    } catch (err) {
      setSaveError(err.message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCreateAndLink = async () => {
    try {
      setIsCreating(true);
      setSaveError(null);
      
      let clientId = selectedClient?.id;
      
      // If we have a client name but no matching Toggl client, create one
      if (clientName && !clientId) {
        const newClient = await togglService.createClient(clientName);
        clientId = newClient.id;
      }
      
      // Create project in Toggl with client
      const newTogglProject = await togglService.createProject(projectTitle, clientId);
      
      // Link the project
      await handleSelect(newTogglProject);
      
      // Close dropdown
      setIsOpen(false);
      setSearchTerm('');
    } catch (err) {
      setSaveError(err.message);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {isSaving ? (
        <div className="inline-flex items-center px-4 py-2 text-sm bg-gray-50 border border-gray-300 text-gray-500 rounded-md">
          <div className="animate-spin h-4 w-4 mr-2 border-2 border-gray-500 border-t-transparent rounded-full"></div>
          Saving...
        </div>
      ) : selectedProject || currentTogglId ? (
        <div className="flex items-center space-x-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
            className="inline-flex items-center px-4 py-2 text-sm bg-green-50 border border-green-300 text-green-700 rounded-md hover:bg-green-100 group"
          >
            <CheckCircleIcon className="w-5 h-5 mr-2 text-green-500 flex-shrink-0" />
            <div className="flex flex-col items-start overflow-hidden">
              <span className="truncate">Connected to Toggl</span>
              {selectedProject?.name && (
                <span className="text-xs text-green-600 truncate">
                  {selectedProject.name}
                  {selectedProject.client_name && ` (${selectedProject.client_name})`}
                </span>
              )}
            </div>
            <ChevronDownIcon className="w-4 h-4 ml-2 flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity" />
          </button>
          <button
            onClick={handleDisconnect}
            className="p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-md transition-colors"
            title="Disconnect Toggl project"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
      ) : (
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex justify-between items-center w-64 px-4 py-2 text-sm bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          disabled={projectsLoading}
        >
          {projectsLoading ? (
            <div className="flex items-center">
              <div className="animate-spin h-4 w-4 mr-2 border-2 border-gray-500 border-t-transparent rounded-full"></div>
              Loading...
              {retryCount > 0 && ` (Retry ${retryCount}/${maxRetries})`}
            </div>
          ) : (
            <>
              <span>Select Toggl Project</span>
              <ChevronDownIcon className="w-4 h-4 ml-2 flex-shrink-0" />
            </>
          )}
        </button>
      )}

      {saveError && (
        <div className="absolute top-full mt-2 w-full p-2 bg-red-50 border border-red-200 rounded-md text-sm text-red-600">
          {saveError}
        </div>
      )}

      {isOpen && createPortal(
        <div 
          className="fixed bg-white rounded-md shadow-lg toggl-project-dropdown-portal"
          style={{
            width: '24rem',
            maxHeight: '24rem',
            top: dropdownRef.current?.getBoundingClientRect().bottom + 8 || 0,
            left: dropdownRef.current?.getBoundingClientRect().left || 0,
            zIndex: 50
          }}
        >
          <div className="sticky top-0 p-2 border-b border-gray-200 bg-white">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search projects..."
              className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              autoFocus
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          
          <div className="overflow-y-auto" style={{ maxHeight: '20rem' }}>
            {projectsLoading || clientsLoading ? (
              <div className="p-4 text-center text-gray-500">
                <div className="animate-spin h-5 w-5 mx-auto mb-2 border-2 border-gray-500 border-t-transparent rounded-full"></div>
                Loading...
                {retryCount > 0 && (
                  <div className="text-xs text-gray-400 mt-1">
                    Retry attempt {retryCount}/{maxRetries}
                  </div>
                )}
              </div>
            ) : saveError || projectsError || clientsError ? (
              <div className="p-4 text-center">
                <div className="text-red-500 mb-2">{saveError || projectsError || clientsError}</div>
                <button
                  onClick={() => {
                    fetchProjects(true);
                    fetchClients();
                  }}
                  className="text-sm text-blue-500 hover:text-blue-600"
                >
                  Try Again
                </button>
              </div>
            ) : filteredProjects.length === 0 ? (
              <div className="p-4 flex flex-col items-center space-y-4">
                <div className="text-gray-500 text-center">
                  {searchTerm ? 'No matching projects' : 'No projects available'}
                </div>
                {projectTitle && (
                  <div className="w-full max-w-sm space-y-4">
                    {clientName && !selectedClient && (
                      <div className="text-sm text-yellow-600 bg-yellow-50 p-3 rounded-md">
                        Note: Will create new client "{clientName}" in Toggl
                      </div>
                    )}
                    <button
                      onClick={handleCreateAndLink}
                      disabled={isCreating}
                      className="w-full inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isCreating ? (
                        <>
                          <div className="animate-spin h-4 w-4 mr-2 border-2 border-white border-t-transparent rounded-full"></div>
                          Creating...
                        </>
                      ) : (
                        <>
                          Create "{projectTitle}"{selectedClient ? ` for ${selectedClient.name}` : clientName ? ` for ${clientName}` : ''}
                        </>
                      )}
                    </button>
                  </div>
                )}
                {saveError && (
                  <div className="text-sm text-red-600 text-center mt-2">
                    {saveError}
                  </div>
                )}
              </div>
            ) : (
              filteredProjects.map((project) => (
                <div
                  key={project.id}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSelect(project);
                  }}
                >
                  <div className="text-sm font-medium text-gray-900">{project.name}</div>
                  {project.client_name && (
                    <div className="text-xs text-gray-500">{project.client_name}</div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

const SyncStatus = () => {
  const [syncLogs, setSyncLogs] = useState({
    notion: null,
    toggl: null,
  });
  const [loading, setLoading] = useState(true);
  const [syncing, setSyncing] = useState({
    notion: false,
    toggl: false
  });

  const fetchSyncLogs = async () => {
    try {
      const { data, error } = await supabase
        .from('sync_logs')
        .select('*')
        .in('type', ['notion_projects', 'toggl_time'])
        .order('sync_date', { ascending: false })
        .limit(2);

      if (error) throw error;

      const logs = {
        notion: data.find(log => log.type === 'notion_projects'),
        toggl: data.find(log => log.type === 'toggl_time')
      };

      setSyncLogs(logs);
    } catch (err) {
      console.error('Error fetching sync logs:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSyncLogs();
    // Refresh every minute
    const interval = setInterval(fetchSyncLogs, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleSync = async (type) => {
    if (syncing[type]) return; // Prevent multiple clicks while syncing

    setSyncing(prev => ({ ...prev, [type]: true }));
    try {
      const functionName = type === 'notion' ? 'sync-notion-projects' : 'sync-toggl-time';
      const response = await fetch(
        `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/${functionName}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_ANON_KEY}`
          }
        }
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || `Failed to trigger ${type} sync`);
      }

      // Refresh sync logs after a short delay to allow for sync to start
      setTimeout(() => {
        fetchSyncLogs();
      }, 2000);

    } catch (err) {
      console.error(`Error triggering ${type} sync:`, err);
    } finally {
      setSyncing(prev => ({ ...prev, [type]: false }));
    }
  };

  const getSyncStatus = (log) => {
    if (!log) return { color: 'gray', text: 'Never synced', border: 'border-gray-300', bg: 'bg-gray-50' };
    
    try {
      const timeAgo = formatDistanceToNow(new Date(log.sync_date))
        .replace(' minutes', ' min')
        .replace(' minute', ' min')
        .replace(' hours', ' h')
        .replace(' hour', ' h')
        .replace(' days', ' d')
        .replace(' day', ' d')
        .replace(' months', ' mo')
        .replace(' month', ' mo')
        .replace(' years', ' y')
        .replace(' year', ' y')
        .replace('about ', '')
        .replace('less than ', '<')
        .replace('almost ', '~');
      
      const status = log.status === 'success' 
        ? { 
            color: 'green', 
            text: `${timeAgo} ago`,
            border: 'border-green-300',
            bg: 'bg-green-50'
          }
        : { 
            color: 'red', 
            text: `Failed ${timeAgo} ago`,
            border: 'border-red-300',
            bg: 'bg-red-50'
          };
      
      return status;
    } catch (err) {
      console.error('Error formatting sync status:', err);
      return { color: 'gray', text: 'Error getting status', border: 'border-gray-300', bg: 'bg-gray-50' };
    }
  };

  const notionStatus = getSyncStatus(syncLogs.notion);
  const togglStatus = getSyncStatus(syncLogs.toggl);

  return (
    <div className="flex items-center space-x-3">
      <button
        onClick={() => handleSync('notion')}
        disabled={syncing.notion}
        className={`flex items-center space-x-2 px-3 py-2 rounded-md border relative
          ${notionStatus.border} ${notionStatus.bg} 
          transition-all duration-150 
          hover:bg-opacity-75 
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${notionStatus.color}-500
          ${syncing.notion ? 'cursor-wait' : 'cursor-pointer'}
          ${syncing.notion ? 'animate-pulse' : ''}`}
      >
        <NotionIcon className={`h-5 w-5 text-${notionStatus.color}-500 ${syncing.notion ? 'opacity-50' : ''}`} />
        <span className={`text-sm text-${notionStatus.color}-600 ${syncing.notion ? 'opacity-50' : ''}`}>
          {syncing.notion ? 'Syncing...' : notionStatus.text}
        </span>
        {(loading || syncing.notion) && (
          <div className="flex items-center">
            <ArrowPathIcon className="h-4 w-4 text-gray-400 animate-spin" />
          </div>
        )}
        {syncing.notion && (
          <div className="absolute inset-0 bg-white bg-opacity-10 rounded-md flex items-center justify-center">
            <div className="w-5 h-5 border-t-2 border-b-2 border-current rounded-full animate-spin" />
          </div>
        )}
      </button>
      <button
        onClick={() => handleSync('toggl')}
        disabled={syncing.toggl}
        className={`flex items-center space-x-2 px-3 py-2 rounded-md border relative
          ${togglStatus.border} ${togglStatus.bg}
          transition-all duration-150 
          hover:bg-opacity-75 
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${togglStatus.color}-500
          ${syncing.toggl ? 'cursor-wait' : 'cursor-pointer'}
          ${syncing.toggl ? 'animate-pulse' : ''}`}
      >
        <TogglIcon className={`h-5 w-5 text-${togglStatus.color}-500 ${syncing.toggl ? 'opacity-50' : ''}`} />
        <span className={`text-sm text-${togglStatus.color}-600 ${syncing.toggl ? 'opacity-50' : ''}`}>
          {syncing.toggl ? 'Syncing...' : togglStatus.text}
        </span>
        {(loading || syncing.toggl) && (
          <div className="flex items-center">
            <ArrowPathIcon className="h-4 w-4 text-gray-400 animate-spin" />
          </div>
        )}
        {syncing.toggl && (
          <div className="absolute inset-0 bg-white bg-opacity-10 rounded-md flex items-center justify-center">
            <div className="w-5 h-5 border-t-2 border-b-2 border-current rounded-full animate-spin" />
          </div>
        )}
      </button>
    </div>
  );
};

const ProjectConnections = () => {
  const [supabaseProjects, setSupabaseProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Filter states
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchSupabaseProjects = async () => {
    try {
      setLoading(true);
      const { data, error } = await projectConnectionService.getSupabaseProjects();
      if (error) throw error;
      setSupabaseProjects(data);
    } catch (err) {
      setError('Failed to fetch projects: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSupabaseProjects();
  }, []);

  // Get unique options for filters
  const getUniqueStatuses = () => {
    const uniqueStatuses = [...new Set(supabaseProjects.map(p => p.status))].filter(Boolean);
    return uniqueStatuses.sort((a, b) => STATUS_ORDER.indexOf(a) - STATUS_ORDER.indexOf(b));
  };
  const getUniqueClients = () => [...new Set(supabaseProjects.map(p => p.client_name))].filter(Boolean);

  // Filter projects based on selected filters and search query
  const filteredProjects = useMemo(() => {
    let filtered = [...supabaseProjects];
    
    // Apply search filter
    if (searchQuery) {
      const searchLower = searchQuery.toLowerCase();
      filtered = filtered.filter(project => 
        project.title?.toLowerCase().includes(searchLower) ||
        project.client_name?.toLowerCase().includes(searchLower)
      );
    }

    // Apply status filter
    if (selectedStatuses.length > 0) {
      filtered = filtered.filter(project => selectedStatuses.includes(project.status));
    }

    // Apply client filter
    if (selectedClients.length > 0) {
      filtered = filtered.filter(project => selectedClients.includes(project.client_name));
    }

    return filtered;
  }, [supabaseProjects, searchQuery, selectedStatuses, selectedClients]);

  if (loading) return <Loader message="Loading projects..." />;
  if (error) return <div className="text-red-500 p-4">{error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Project Connections</h1>
        <SyncStatus />
      </div>

      {/* Search and Filters Row */}
      <div className="flex items-center space-x-4 mb-6">
        {/* Search */}
        <div className="relative flex-1 max-w-md">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search projects or clients..."
            className="w-full px-4 py-2 pl-10 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
          </div>
          {searchQuery && (
            <button
              onClick={() => setSearchQuery('')}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              <XMarkIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" />
            </button>
          )}
        </div>

        {/* Filters */}
        <FilterDropdown
          options={getUniqueStatuses()}
          selectedOptions={selectedStatuses}
          onChange={setSelectedStatuses}
          placeholder="Status"
        />
        <FilterDropdown
          options={getUniqueClients()}
          selectedOptions={selectedClients}
          onChange={setSelectedClients}
          placeholder="Client"
        />
      </div>

      {/* Results Count Row */}
      {searchQuery && (
        <div className="mb-6 text-sm text-gray-500">
          Found {filteredProjects.length} matching projects
        </div>
      )}

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Project Details
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Toggl
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredProjects.map((project) => (
              <tr key={project.id} className="hover:bg-gray-50">
                <td className="px-6 py-4">
                  <div className="flex flex-col">
                    <div className="text-sm font-medium text-gray-900">
                      {project.title}
                    </div>
                    <div className="flex items-center space-x-2 mt-1">
                      <span className="text-xs text-gray-500">
                        {project.client_name || 'No Client'}
                      </span>
                      {project.appetite && (
                        <span className="text-xs px-1.5 py-0.5 bg-blue-50 text-blue-700 rounded-full">
                          {project.appetite.includes('Week') ? project.appetite.replace('Weeks', 'w').replace('Week', 'w') : project.appetite}
                        </span>
                      )}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    project.status === 'In Progress' ? 'bg-green-100 text-green-800' :
                    project.status === 'Blocked' ? 'bg-red-100 text-red-800' :
                    'bg-gray-100 text-gray-800'
                  }`}>
                    {project.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center space-x-4">
                    <TogglProjectSelect 
                      projectId={project.id}
                      currentTogglId={project.toggl_id}
                      projectTitle={project.title}
                      clientName={project.client_name}
                      onSelect={(togglId, togglName) => {
                        setSupabaseProjects(currentProjects =>
                          currentProjects.map(p => {
                            if (p.id === project.id) {
                              return { ...p, toggl_id: togglId, toggl_project_name: togglName };
                            }
                            return p;
                          })
                        );
                      }}
                    />
                    <div className="flex items-center space-x-2">
                      {project.notion_project_id && (
                        <button
                          onClick={() => {
                            const cleanId = project.notion_project_id.replace(/-/g, '');
                            window.open(`https://www.notion.so/inovo/${cleanId}`, '_blank');
                          }}
                          className="p-1 hover:bg-gray-100 rounded-md transition-colors group inline-flex"
                          title="Open in Notion"
                        >
                          <NotionIcon className="h-4 w-4 text-gray-500 group-hover:text-gray-700" />
                        </button>
                      )}
                      {project.toggl_id && (
                        <button
                          onClick={() => window.open(`https://track.toggl.com/reports/detailed/3098605/period/thisYear/projects/${project.toggl_id}`, '_blank')}
                          className="p-1 hover:bg-gray-100 rounded-md transition-colors group inline-flex"
                          title="Open in Toggl"
                        >
                          <TogglIcon className="h-4 w-4 text-[#E57CD8] group-hover:text-[#E57CD8] fill-current" />
                        </button>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectConnections; 