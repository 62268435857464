import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { supabase } from '../../utils/supabaseClient';
import NotionIcon from '../NotionIcon';

const TogglIcon = ({ className }) => (
  <svg 
    className={className} 
    viewBox="0 0 24 24" 
    role="img" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 0C5.373 0 0 5.37 0 12s5.373 12 12 12c6.63 0 12-5.37 12-12S18.63 0 12 0zm-.84 4.67h1.68v8.36h-1.68V4.67zM12 18.155c-3.24-.002-5.865-2.63-5.864-5.868 0-2.64 1.767-4.956 4.314-5.655v1.71c-1.628.64-2.698 2.21-2.695 3.96 0 2.345 1.903 4.244 4.248 4.243 2.344-.002 4.244-1.903 4.243-4.248 0-1.745-1.07-3.312-2.694-3.95V6.63c2.55.7 4.314 3.018 4.314 5.66 0 3.24-2.626 5.864-5.865 5.864z"/>
  </svg>
);

const ProjectTimeTracking = ({ projectId, notionProjectId }) => {
  const [timeData, setTimeData] = useState([]);
  const [userTotals, setUserTotals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalHours, setTotalHours] = useState(0);
  const [averageDailyHours, setAverageDailyHours] = useState(0);
  const [userMap, setUserMap] = useState(new Map());
  const [projectStartDate, setProjectStartDate] = useState(null);
  const [projectEndDate, setProjectEndDate] = useState(null);

  useEffect(() => {
    fetchTimeData();
  }, [projectId]);

  const fetchTimeData = async () => {
    if (!projectId) return;
    
    setLoading(true);
    setError(null);
    
    try {
      // Fetch user data first to build the mapping
      const { data: userMappingData, error: userError } = await supabase
        .from('users')
        .select('id, name, toggl_user_id')
        .not('toggl_user_id', 'is', null);

      if (userError) {
        console.error('Error fetching user data:', userError);
        throw userError;
      }

      // Create a map of Toggl user IDs to user names
      const newUserMap = new Map(
        userMappingData.map(user => [Number(user.toggl_user_id), { name: user.name, id: user.id }])
      );
      setUserMap(newUserMap);

      // Fetch project details to get the toggl_id and date range
      const { data: projectData, error: projectError } = await supabase
        .from('projects')
        .select('toggl_id, start_date, end_date')
        .eq('id', projectId)
        .single();

      if (projectError) {
        console.error('Error fetching project data:', projectError);
        throw projectError;
      }

      const togglProjectId = projectData?.toggl_id;
      const startDate = projectData?.start_date ? new Date(projectData.start_date) : null;
      const endDate = projectData?.end_date ? new Date(projectData.end_date) : null;
      
      setProjectStartDate(startDate);
      setProjectEndDate(endDate);

      if (!togglProjectId) {
        setError('No Toggl project ID found for this project');
        setLoading(false);
        return;
      }

      // Fetch all time tracking data for the specific project without date filtering
      const { data, error } = await supabase
        .from('time_tracking')
        .select(`
          id,
          project_id,
          toggl_entry_id,
          toggl_project_id,
          toggl_user_id,
          description,
          duration,
          start_time,
          stop_time,
          created_at
        `)
        .eq('toggl_project_id', togglProjectId)
        .order('start_time', { ascending: true });

      if (error) throw error;

      if (!data || data.length === 0) {
        // If we have project dates but no time data, create empty timeline
        if (startDate && endDate) {
          const emptyTimelineData = generateEmptyTimeline(startDate, endDate);
          setTimeData(emptyTimelineData);
        } else {
          setTimeData([]);
        }
        
        setUserTotals([]);
        setTotalHours(0);
        setAverageDailyHours(0);
        setLoading(false);
        return;
      }

      // Process data for the graph (daily totals)
      const dailyTotals = processDataForGraph(data, startDate, endDate);
      setTimeData(dailyTotals);

      // Calculate total hours
      const total = data.reduce((sum, entry) => sum + (entry.duration / 3600000), 0);
      setTotalHours(total);

      // Calculate average daily hours (only for days with data)
      const daysWithData = Object.keys(
        data.reduce((acc, entry) => {
          const date = new Date(entry.start_time);
          const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          acc[formattedDate] = true;
          return acc;
        }, {})
      ).length;
      
      setAverageDailyHours(daysWithData > 0 ? total / daysWithData : 0);

      // Process user totals
      const userData = processUserTotals(data);
      setUserTotals(userData);

    } catch (error) {
      console.error('Error fetching time data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Generate a timeline with all dates between start and end, even with no time entries
  const generateEmptyTimeline = (startDate, endDate) => {
    if (!startDate || !endDate) return [];
    
    const timeline = [];
    // Start directly from the start date (no buffer)
    const currentDate = new Date(startDate);
    
    // End directly at the end date (no buffer)
    const bufferEndDate = new Date(endDate);
    
    while (currentDate <= bufferEndDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      const day = currentDate.getDate();
      const dateKey = `${year}-${month + 1}-${day}`;
      const formattedDate = `${month + 1}/${day}`;
      const isWeekend = currentDate.getDay() === 0 || currentDate.getDay() === 6;
      
      timeline.push({
        date: formattedDate,
        dateKey: dateKey,
        hours: 0,
        entries: 0,
        isWeekend
      });
      
      // Move to next day
      currentDate.setDate(currentDate.getDate() + 1);
    }
    
    return timeline;
  };

  const processDataForGraph = (data, startDate, endDate) => {
    // First create a complete timeline with all dates
    const allDates = {};
    
    // If we have start and end dates, fill in all dates between them
    if (startDate && endDate) {
      // Start directly from the start date (no buffer)
      const currentDate = new Date(startDate);
      
      // End directly at the end date (no buffer)
      const bufferEndDate = new Date(endDate);
      
      while (currentDate <= bufferEndDate) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const day = currentDate.getDate();
        const dateKey = `${year}-${month + 1}-${day}`;
        const formattedDate = `${month + 1}/${day}`;
        const isWeekend = currentDate.getDay() === 0 || currentDate.getDay() === 6;
        
        allDates[dateKey] = { 
          date: formattedDate,
          dateKey: dateKey,
          hours: 0,
          entries: 0,
          isWeekend
        };
        
        // Move to next day
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
    
    // Now add the actual time tracking data
    data.forEach(entry => {
      const date = new Date(entry.start_time);
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const dateKey = `${year}-${month + 1}-${day}`;
      const formattedDate = `${month + 1}/${day}`;
      const isWeekend = date.getDay() === 0 || date.getDay() === 6;
      const hoursSpent = entry.duration / 3600000;
      
      if (!allDates[dateKey]) {
        allDates[dateKey] = { 
          date: formattedDate,
          dateKey: dateKey,
          hours: 0,
          entries: 0,
          isWeekend
        };
      }
      
      allDates[dateKey].hours += hoursSpent;
      allDates[dateKey].entries += 1;
    });

    // Convert to array and sort by date
    return Object.values(allDates).sort((a, b) => {
      const [aYear, aMonth, aDay] = a.dateKey.split('-').map(Number);
      const [bYear, bMonth, bDay] = b.dateKey.split('-').map(Number);
      return (aYear - bYear) || (aMonth - bMonth) || (aDay - bDay);
    });
  };

  const processUserTotals = (data) => {
    const userTotals = {};
    
    data.forEach(entry => {
      const userId = Number(entry.toggl_user_id);
      const hoursSpent = entry.duration / 3600000;
      const userInfo = userMap.get(userId);
      
      if (!userTotals[userId]) {
        userTotals[userId] = {
          userId,
          userName: userInfo?.name || 'Unknown User',
          hours: 0,
          entries: 0
        };
      }
      userTotals[userId].hours += hoursSpent;
      userTotals[userId].entries += 1;
    });

    return Object.values(userTotals)
      .sort((a, b) => b.hours - a.hours);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-4 border rounded shadow-lg">
          <p className="font-bold">{label} {data.isWeekend ? '(Weekend)' : ''}</p>
          <p className="text-blue-600">Hours: {payload[0].value.toFixed(2)}</p>
          <p className="text-gray-600">Entries: {data.entries}</p>
        </div>
      );
    }
    return null;
  };

  const handleOpenInToggl = () => {
    if (!projectId) return;
    window.open(`https://track.toggl.com/reports/detailed/3098605/period/thisYear/projects/${projectId}`, '_blank');
  };

  const getInitials = (name) => {
    if (!name || name === 'Unknown User') return '??';
    const names = name.split(' ');
    if (names.length === 1) return names[0].charAt(0).toUpperCase();
    return (names[0].charAt(0) + names[names.length - 1].charAt(0)).toUpperCase();
  };

  const getAvatarColor = (name) => {
    if (!name || name === 'Unknown User') return 'bg-gray-200';
    
    const colors = [
      'bg-blue-500',
      'bg-green-500',
      'bg-yellow-500',
      'bg-purple-500',
      'bg-pink-500',
      'bg-indigo-500',
      'bg-red-500',
      'bg-teal-500'
    ];
    
    // Simple hash function to get consistent color
    const hash = name.split('').reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    
    return colors[Math.abs(hash) % colors.length];
  };

  // Find the date in the timeData array
  const getDateFromTimeData = (dateToFind) => {
    if (!dateToFind || !timeData.length) return null;
    
    const targetDate = new Date(dateToFind);
    const targetYear = targetDate.getFullYear();
    const targetMonth = targetDate.getMonth() + 1;
    const targetDay = targetDate.getDate();
    
    // Format the target date in the same way as our data keys
    const targetKey = `${targetYear}-${targetMonth}-${targetDay}`;
    
    // Find the date in our timeData array
    return timeData.find(item => item.dateKey === targetKey);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">Time Tracking</h2>
        {projectStartDate && projectEndDate && (
          <div className="text-sm text-gray-500">
            Project Duration: {projectStartDate.toLocaleDateString()} - {projectEndDate.toLocaleDateString()}
          </div>
        )}
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      {loading ? (
        <div className="flex items-center justify-center h-32">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#307FE2]"></div>
        </div>
      ) : (
        <>
          {timeData.length === 0 ? (
            <div className="bg-white rounded-2xl text-center">
              <p className="text-gray-500">No time tracking data found for this project.</p>
            </div>
          ) : (
            <>
              {/* Time Overview Graph */}
              <div className="bg-white rounded-2xl">
                <div className="h-[300px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={timeData} margin={{ top: 20, right: 30, left: 0, bottom: 25 }}>
                      <CartesianGrid 
                        strokeDasharray="3 3" 
                        vertical={false} 
                        horizontal={false}
                      />
                      <XAxis 
                        dataKey="date" 
                        tick={(props) => {
                          const { x, y, payload } = props;
                          
                          // Check if this date is the project start or end date
                          const isStartDate = projectStartDate && 
                            getDateFromTimeData(projectStartDate)?.date === payload.value;
                          
                          const isEndDate = projectEndDate && 
                            getDateFromTimeData(projectEndDate)?.date === payload.value;
                          
                          return (
                            <g transform={`translate(${x},${y})`}>
                              <text 
                                x={0} 
                                y={0} 
                                dy={10} 
                                textAnchor="middle" 
                                fontSize={12} 
                                fontWeight={isStartDate || isEndDate ? 700 : 400}
                                fill={isStartDate ? "#10B981" : isEndDate ? "#EF4444" : "#666"}
                              >
                                {payload.value}
                              </text>
                              {isStartDate && (
                                <text 
                                  x={0} 
                                  y={0} 
                                  dy={25} 
                                  textAnchor="middle" 
                                  fontSize={10} 
                                  fill="#10B981"
                                  fontWeight={600}
                                >
                                  Start
                                </text>
                              )}
                              {isEndDate && (
                                <text 
                                  x={0} 
                                  y={0} 
                                  dy={25} 
                                  textAnchor="middle" 
                                  fontSize={10} 
                                  fill="#EF4444"
                                  fontWeight={600}
                                >
                                  End
                                </text>
                              )}
                            </g>
                          );
                        }}
                        interval={0} // Show all ticks for each day
                        tickSize={4} // Ensure visible notches
                      />
                      <YAxis 
                        axisLine={false}
                        tickLine={false}
                        tick={false} // Hide the Y-axis numbers
                        domain={[0, 10]} // Adjusted domain to max 10 hours
                      />
                      <Tooltip content={<CustomTooltip />} />
                      
                      {/* Add explicit horizontal reference lines */}
                      <ReferenceLine y={2} stroke="#E5E7EB" strokeDasharray="3 3" />
                      <ReferenceLine y={4} stroke="#E5E7EB" strokeDasharray="3 3" />
                      <ReferenceLine y={6} stroke="#E5E7EB" strokeDasharray="3 3" />
                      <ReferenceLine y={8} stroke="#E5E7EB" strokeDasharray="3 3" />
                      <ReferenceLine y={10} stroke="#E5E7EB" strokeDasharray="3 3" />
                      
                      {/* Restore project start date reference line */}
                      {projectStartDate && getDateFromTimeData(projectStartDate) && (
                        <ReferenceLine
                          x={getDateFromTimeData(projectStartDate)?.date}
                          stroke="#10B981"
                          strokeWidth={2}
                          strokeDasharray="3 3"
                        />
                      )}
                      
                      {/* Restore project end date reference line */}
                      {projectEndDate && getDateFromTimeData(projectEndDate) && (
                        <ReferenceLine
                          x={getDateFromTimeData(projectEndDate)?.date}
                          stroke="#EF4444"
                          strokeWidth={2}
                          strokeDasharray="3 3"
                        />
                      )}
                      
                      <Bar 
                        dataKey="hours" 
                        name="Hours"
                        fill="#307FE2"
                        fillOpacity={0.9}
                        shape={(props) => {
                          const { fill, x, y, width, height, payload } = props;
                          
                          // Check if this date is the project start or end date
                          const isStartDate = projectStartDate && 
                            getDateFromTimeData(projectStartDate)?.date === payload.date;
                          
                          const isEndDate = projectEndDate && 
                            getDateFromTimeData(projectEndDate)?.date === payload.date;
                          
                          // Determine the fill color based on whether it's a start date, end date, weekend, or regular day
                          let barFill = '#307FE2'; // Default color for workdays
                          
                          if (isStartDate) {
                            barFill = '#10B981'; // Green for start date
                          } else if (isEndDate) {
                            barFill = '#EF4444'; // Red for end date
                          } else if (payload.isWeekend) {
                            barFill = '#D1D5DB'; // Gray for weekends
                          }
                          
                          return (
                            <g>
                              <rect
                                x={x}
                                y={y}
                                width={width}
                                height={height}
                                fill={barFill}
                              />
                              {/* Only add text if there are hours logged */}
                              {payload.hours > 0 && (
                                <text
                                  x={x + width / 2}
                                  y={y + 5}
                                  textAnchor="middle"
                                  dominantBaseline="hanging"
                                  fill="white"
                                  fontSize={10}
                                  fontWeight={700}
                                >
                                  {Math.round(payload.hours)}
                                </text>
                              )}
                            </g>
                          );
                        }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectTimeTracking; 