import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CalendarIcon, ClockIcon } from 'lucide-react';
import { Draggable } from 'react-beautiful-dnd';

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const calculateDateDifference = (endDateString) => {
  if (!endDateString) return null;
  const today = new Date();
  const endDate = new Date(endDateString);
  const diffTime = endDate.getTime() - today.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const ProjectCard = ({ project, index, onClientClick, filterParams }) => {
  const navigate = useNavigate();
  const daysRemaining = calculateDateDifference(project.end_date);
  
  let dateStatusClass = '';
  let dateStatusText = '';
  
  if (daysRemaining === null) {
    dateStatusClass = 'bg-gray-100 text-gray-600';
    dateStatusText = 'No end date set';
  } else if (daysRemaining < 0) {
    dateStatusClass = 'bg-red-100 text-red-600';
    dateStatusText = `${Math.abs(daysRemaining)} days overdue`;
  } else if (daysRemaining === 0) {
    dateStatusClass = 'bg-yellow-100 text-yellow-600';
    dateStatusText = 'Due today';
  } else {
    dateStatusClass = 'bg-green-100 text-green-600';
    dateStatusText = `${daysRemaining} days remaining`;
  }

  const handleClientClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (project.client?.name) {
      navigate(`/projects?client=${encodeURIComponent(project.client.name)}`);
    }
  };

  const renderClientIcon = () => {
    if (project.client?.icon) {
      if (project.client.icon.type === 'emoji') {
        return <span className="text-2xl">{project.client.icon.emoji}</span>;
      } else if (project.client.icon.type === 'url') {
        return (
          <img 
            src={project.client.icon.url} 
            alt="" 
            className="w-8 h-8 rounded-full object-cover border border-gray-200"
          />
        );
      }
    }
    return null;
  };

  return (
    <Draggable draggableId={String(project.id)} index={index}>
      {(provided) => (
        <Link
          to={`/projects/${project.id}${filterParams}`}
          state={{ project, filterParams }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="bg-white rounded-xl shadow-md overflow-hidden flex flex-col cursor-pointer hover:shadow-lg transition-shadow duration-200"
        >
          <div className="bg-gray-50 px-4 py-2 border-b border-gray-200">
            <div className="flex items-start">
              <div className="flex-shrink-0 mt-1">
                {renderClientIcon()}
              </div>
              <div className="flex-grow min-w-0">
                <h2 className="text-lg font-semibold text-gray-800 leading-tight line-clamp-2">
                  {project.name}
                </h2>
                {project.title && (
                  <p className="text-sm font-medium text-gray-600 mt-1 line-clamp-2">
                    {project.title}
                  </p>
                )}
                <p 
                  className="text-sm text-gray-500 hover:text-gray-700 mt-1 cursor-pointer truncate"
                  onClick={handleClientClick}
                >
                  {project.client?.name || 'No Client'}
                </p>
              </div>
            </div>
          </div>
          <div className="p-4 space-y-4 flex-grow">
            <div className="flex items-center text-sm text-gray-600">
              <CalendarIcon className="w-4 h-4 mr-1.5 text-gray-400" />
              <span className="mr-2">
                {project.start_date ? formatDate(project.start_date) : 'Start: N/A'} 
                {' - '} 
                {project.end_date ? formatDate(project.end_date) : 'End: N/A'}
              </span>
            </div>
            <div className="flex items-center text-sm text-gray-600">
              <ClockIcon className="w-4 h-4 mr-1.5 text-gray-400" />
              Appetite: {project.appetite || 'N/A'}
            </div>
          </div>
          <div className={`px-4 py-2 text-sm font-medium ${dateStatusClass}`}>
            {dateStatusText}
          </div>
        </Link>
      )}
    </Draggable>
  );
};

export default ProjectCard;