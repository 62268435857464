import React, { useState, useEffect, useRef, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CalendarIcon, PlusIcon, XMarkIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { PencilIcon, TrashIcon, DocumentDuplicateIcon } from '@heroicons/react/24/solid';
import { supabase } from '../utils/supabaseClient';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { Button } from "../components/ui/button"
import TransactionTable from '../components/cashflow/TransactionTable';
import { ActionDropdown } from '../components/cashflow/ActionDropdown';
import StatsBoxes from '../components/cashflow/StatsBoxes';
import MonthSelector from '../components/cashflow/MonthSelector';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select"
import TransactionModal from '../components/cashflow/TransactionModal';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Something went wrong. Please try again later.</div>;
    }

    return this.props.children;
  }
}

const CashFlowErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary>
      {children}
    </ErrorBoundary>
  );
};

const INITIAL_TRANSACTION_STATE = {
  project_id: '',
  amount: '',
  date: new Date().toLocaleDateString('en-CA'),
  payment_method: 'Invoice',
  memo: '',
  is_recurring: false,
  recurring_frequency: null,
  recurring_end_date: null
};

const useTransactionHandling = (fetchPayments) => {
  const [newIncome, setNewIncome] = useState({
    ...INITIAL_TRANSACTION_STATE,
    payment_type: 'credit'
  });
  const [newExpense, setNewExpense] = useState({
    ...INITIAL_TRANSACTION_STATE,
    payment_type: 'debit'
  });
  const [isAddingIncome, setIsAddingIncome] = useState(false);
  const [isAddingExpense, setIsAddingExpense] = useState(false);
  const [newlyAddedId, setNewlyAddedId] = useState(null);
  const [newlyAddedExpenseId, setNewlyAddedExpenseId] = useState(null);

  const handleAddIncome = async () => {
    if (!newIncome.amount || !newIncome.date) {
      alert('Please fill in all required fields (amount and date)');
      return;
    }

    if (newIncome.is_recurring && !newIncome.recurring_frequency) {
      alert('Please select a frequency for recurring payments');
      return;
    }

    setIsAddingIncome(true);

    try {
      const paymentData = {
        project_id: newIncome.project_id || null,
        amount: Number(newIncome.amount),
        date: newIncome.date,
        payment_type: 'credit',
        payment_method: newIncome.payment_method,
        notion_id: 'cashflow',
        memo: newIncome.memo,
        is_recurring: newIncome.is_recurring || false,
        recurring_frequency: newIncome.recurring_frequency || null,
        recurring_end_date: newIncome.recurring_end_date || null,
        next_recurring_date: newIncome.is_recurring ? newIncome.date : null
      };

      const { data, error } = await supabase
        .from('payments')
        .insert([paymentData])
        .select();

      if (error) throw error;

      setNewlyAddedId(data[0].id);
      await fetchPayments();
      resetTransactionState('income');

      setTimeout(() => setNewlyAddedId(null), 3000);
    } catch (error) {
      console.error('Error adding income:', error);
      alert(`Failed to add income: ${error.message}`);
    } finally {
      setIsAddingIncome(false);
    }
  };

  const handleAddExpense = async () => {
    if (!newExpense.amount || !newExpense.date) {
      alert('Please fill in all required fields (amount and date)');
      return;
    }

    if (newExpense.is_recurring && !newExpense.recurring_frequency) {
      alert('Please select a frequency for recurring payments');
      return;
    }

    setIsAddingExpense(true);

    try {
      const paymentData = {
        project_id: newExpense.project_id || null,
        amount: Number(newExpense.amount),
        date: newExpense.date,
        payment_type: 'debit',
        payment_method: newExpense.payment_method,
        notion_id: 'cashflow',
        memo: newExpense.memo,
        is_recurring: newExpense.is_recurring || false,
        recurring_frequency: newExpense.recurring_frequency || null,
        recurring_end_date: newExpense.recurring_end_date || null,
        next_recurring_date: newExpense.is_recurring ? newExpense.date : null
      };

      const { data, error } = await supabase
        .from('payments')
        .insert([paymentData])
        .select();

      if (error) throw error;

      setNewlyAddedExpenseId(data[0].id);
      await fetchPayments();
      resetTransactionState('expense');

      setTimeout(() => setNewlyAddedExpenseId(null), 3000);
    } catch (error) {
      console.error('Error adding expense:', error);
      alert(`Failed to add expense: ${error.message}`);
    } finally {
      setIsAddingExpense(false);
    }
  };

  const resetTransactionState = (type) => {
    if (type === 'income') {
      setNewIncome({
        ...INITIAL_TRANSACTION_STATE,
        payment_type: 'credit'
      });
    } else {
      setNewExpense({
        ...INITIAL_TRANSACTION_STATE,
        payment_type: 'debit'
      });
    }
  };

  return {
    newIncome,
    setNewIncome,
    newExpense,
    setNewExpense,
    isAddingIncome,
    isAddingExpense,
    newlyAddedId,
    newlyAddedExpenseId,
    handleAddIncome,
    handleAddExpense,
    resetTransactionState
  };
};

const useProjectHandling = () => {
  const [projects, setProjects] = useState([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [projectError, setProjectError] = useState(null);

  const fetchProjects = async () => {
    setIsLoadingProjects(true);
    setProjectError(null);
    
    try {
      const { data, error } = await supabase
        .from('projects')
        .select(`
          id,
          title,
          is_active,
          status,
          client:client_id (
            id,
            name
          )
        `)
        .order('title')
        .order('is_active', { ascending: false });

      if (error) throw error;

      // Sort projects: active first, then by client name, then by project title
      const sortedProjects = data.sort((a, b) => {
        // First sort by active status
        if (a.is_active !== b.is_active) {
          return b.is_active ? 1 : -1;
        }
        
        // Then sort by client name
        const clientA = a.client?.name || '';
        const clientB = b.client?.name || '';
        if (clientA !== clientB) {
          return clientA.localeCompare(clientB);
        }
        
        // Finally sort by project title
        return a.title.localeCompare(b.title);
      });

      setProjects(sortedProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setProjectError('Failed to load projects. Please try again.');
    } finally {
      setIsLoadingProjects(false);
    }
  };

  return {
    projects,
    isLoadingProjects,
    projectError,
    fetchProjects
  };
};

const useEditHandling = (incomeData, expenseData, fetchPayments) => {
  const [editingIncome, setEditingIncome] = useState(null);
  const [editingExpense, setEditingExpense] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const handleEditStart = (id, type) => {
    setEditingId(id);
    if (type === 'income') {
      setEditingIncome(incomeData.find(income => income.id === id));
    } else {
      setEditingExpense(expenseData.find(expense => expense.id === id));
    }
  };

  const handleEditCancel = () => {
    setEditingId(null);
    setEditingIncome(null);
    setEditingExpense(null);
  };

  const handleUpdateIncome = async () => {
    setIsSaving(true);

    try {
      const updateData = {
        project_id: editingIncome.project_id,
        amount: Number(editingIncome.amount),
        date: editingIncome.date,
        payment_type: 'credit',
        payment_method: editingIncome.payment_method,
        memo: editingIncome.memo
      };

      const { error } = await supabase
        .from('payments')
        .update(updateData)
        .eq('id', editingIncome.id);

      if (error) throw error;

      await fetchPayments();
      setEditingId(null);
      setEditingIncome(null);
    } catch (error) {
      console.error('Error updating income:', error.message);
      alert('Failed to update income. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdateExpense = async () => {
    setIsSaving(true);
    try {
      const { error } = await supabase
        .from('payments')
        .update({
          project_id: editingExpense.project_id,
          amount: Number(editingExpense.amount),
          date: editingExpense.date,
          payment_type: 'debit',
          payment_method: editingExpense.payment_method,
          memo: editingExpense.memo
        })
        .eq('id', editingExpense.id);

      if (error) throw error;

      await fetchPayments();
      setEditingId(null);
      setEditingExpense(null);
    } catch (error) {
      console.error('Error updating expense:', error.message);
      alert('Failed to update expense. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return {
    editingIncome,
    setEditingIncome,
    editingExpense,
    setEditingExpense,
    editingId,
    isSaving,
    handleEditStart,
    handleEditCancel,
    handleUpdateIncome,
    handleUpdateExpense
  };
};

const usePayments = () => {
  const [cashflow, setCashflow] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);

  const generateFutureRecurringTransactions = (data) => {
    const allTransactions = [...data];
    const today = new Date();
    const currentYear = today.getFullYear();
    
    const recurringTransactions = data.filter(t => t.is_recurring);

    recurringTransactions.forEach(transaction => {
      const startDate = new Date(transaction.date + 'T12:00:00');
      const endDate = transaction.recurring_end_date 
        ? new Date(transaction.recurring_end_date + 'T12:00:00')
        : new Date(currentYear, 11, 31, 12, 0, 0); // End of current year if no end date

      let currentDate = new Date(startDate);
      
      while (currentDate <= endDate) {
        // Skip the original transaction date
        if (currentDate > startDate) {
          const futureTransaction = {
            ...transaction,
            date: currentDate.toISOString().split('T')[0],
            id: `${transaction.id}-${currentDate.toISOString()}`,
            is_future_recurring: true,
            original_transaction_id: transaction.id
          };
          
          allTransactions.push(futureTransaction);
        }

        // Calculate next date based on frequency
        const nextDate = new Date(currentDate);
        switch (transaction.recurring_frequency) {
          case 'weekly':
            nextDate.setDate(nextDate.getDate() + 7);
            break;
          case 'monthly':
            nextDate.setMonth(nextDate.getMonth() + 1);
            break;
          case 'quarterly':
            nextDate.setMonth(nextDate.getMonth() + 3);
            break;
          case 'yearly':
            nextDate.setFullYear(nextDate.getFullYear() + 1);
            break;
          default:
            console.warn('Unknown frequency:', transaction.recurring_frequency);
            return;
        }
        currentDate = nextDate;
      }
    });

    return allTransactions;
  };

  const processDataForCashflow = (data) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to start of day
    const currentYear = today.getFullYear();
    
    const monthsInYear = Array.from({ length: 12 }, (_, i) => {
      const date = new Date(currentYear, i, 1, 12, 0, 0);  // Set to noon on the 1st of each month
      const monthLabel = date.toLocaleString('default', { 
        month: 'short',
        year: '2-digit'
      });
      const formattedMonth = `${monthLabel.split(' ')[0]} '${monthLabel.split(' ')[1]}`;
      return formattedMonth;
    }).reduce((acc, month) => {
      acc[month] = { 
        income: 0, 
        projectedIncome: 0, 
        expenses: 0,
        projectedExpenses: 0
      };
      return acc;
    }, {});

    data.forEach(payment => {
      if (!payment.date) return;
      
      const paymentDate = new Date(payment.date + 'T12:00:00');
      paymentDate.setHours(0, 0, 0, 0); // Reset time to start of day for comparison
      
      if (paymentDate.getFullYear() !== currentYear) return;
      
      const month = `${paymentDate.toLocaleString('default', { month: 'short' })} '${paymentDate.getFullYear().toString().slice(-2)}`;

      if (payment.payment_type === 'credit' && payment.amount) {
        if (paymentDate <= today && !payment.is_future_recurring) {
          monthsInYear[month].income += Number(payment.amount);
        } else {
          monthsInYear[month].projectedIncome += Number(payment.amount);
        }
      } else if (payment.payment_type === 'debit' && payment.amount) {
        if (paymentDate <= today && !payment.is_future_recurring) {
          monthsInYear[month].expenses += Number(payment.amount);
        } else {
          monthsInYear[month].projectedExpenses += Number(payment.amount);
        }
      }
    });

    return Object.entries(monthsInYear)
      .map(([month, values]) => ({
        month,
        income: values.income,
        projectedIncome: values.projectedIncome,
        expenses: values.expenses,
        projectedExpenses: values.projectedExpenses
      }));
  };

  const fetchPayments = async () => {
    try {
      const { data, error } = await supabase
        .from('payments')
        .select(`
          *,
          project:project_id (
            id,
            title,
            client:client_id (
              id,
              name
            )
          )
        `)
        .order('date', { ascending: false });

      if (error) throw error;

      const validData = data.filter(payment => 
        payment && 
        payment.date && 
        payment.amount !== null && 
        payment.amount !== undefined
      );

      // Generate all transactions including future recurring ones
      const allTransactions = generateFutureRecurringTransactions(validData);

      // Split into income and expense data after generating recurring transactions
      setIncomeData(allTransactions.filter(payment => payment.payment_type === 'credit'));
      setExpenseData(allTransactions.filter(payment => payment.payment_type === 'debit'));

      // Process data for cashflow chart
      const processedData = processDataForCashflow(allTransactions);
      setCashflow(processedData);
    } catch (error) {
      console.error('Error fetching payments:', error.message);
    }
  };

  return {
    cashflow,
    incomeData,
    expenseData,
    fetchPayments
  };
};

const CashFlow = () => {
  const today = new Date().toLocaleDateString('en-CA');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionType, setTransactionType] = useState(null);

  const {
    cashflow,
    incomeData,
    expenseData,
    fetchPayments
  } = usePayments();

  const {
    projects,
    isLoadingProjects,
    projectError,
    fetchProjects
  } = useProjectHandling();

  const {
    newIncome,
    setNewIncome,
    newExpense,
    setNewExpense,
    isAddingIncome,
    isAddingExpense,
    newlyAddedId,
    newlyAddedExpenseId,
    handleAddIncome,
    handleAddExpense,
    resetTransactionState
  } = useTransactionHandling(fetchPayments);

  const {
    editingIncome,
    setEditingIncome,
    editingExpense,
    setEditingExpense,
    editingId,
    isSaving,
    handleEditStart,
    handleEditCancel,
    handleUpdateIncome,
    handleUpdateExpense
  } = useEditHandling(incomeData, expenseData, fetchPayments);

  useEffect(() => {
    fetchPayments();
    fetchProjects();
  }, []);

  useEffect(() => {
    const handleKeyboardShortcuts = (e) => {
      const isCmdOrCtrl = e.metaKey || e.ctrlKey;
      
      if (isCmdOrCtrl) {
        switch (e.key.toLowerCase()) {
          case 'e':
            e.preventDefault();
            resetTransactionState('expense');
            setIsModalOpen(true);
            setTransactionType('expense');
            break;
            
          case 'i':
            e.preventDefault();
            resetTransactionState('income');
            setIsModalOpen(true);
            setTransactionType('income');
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyboardShortcuts);
    return () => window.removeEventListener('keydown', handleKeyboardShortcuts);
  }, []);

  const handleDeleteIncome = async (id) => {
    try {
      const { error } = await supabase
        .from('payments')
        .delete()
        .eq('id', id);

      if (error) throw error;

      await fetchPayments();
    } catch (error) {
      console.error('Error deleting income:', error.message);
      alert('Failed to delete income. Please try again.');
    }
  };

  const handleDeleteExpense = async (id) => {
    try {
      const { error } = await supabase
        .from('payments')
        .delete()
        .eq('id', id);

      if (error) throw error;

      await fetchPayments();
    } catch (error) {
      console.error('Error deleting expense:', error.message);
      alert('Failed to delete expense. Please try again.');
    }
  };

  const handleDuplicateTransaction = (transaction) => {
    const duplicatedTransaction = {
      ...transaction,
      date: today, // Set to today's date instead of keeping original date
      id: undefined // Remove the id so it creates a new record
    };

    if (transaction.payment_type === 'credit') {
      setNewIncome(duplicatedTransaction);
    } else {
      setNewExpense(duplicatedTransaction);
    }
    
    setTransactionType(transaction.payment_type === 'credit' ? 'income' : 'expense');
    setIsModalOpen(true);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const income = payload[0]?.value || 0;
      const projectedIncome = payload[1]?.value || 0;
      const expenses = payload[2]?.value || 0;
      const projectedExpenses = payload[3]?.value || 0;
      const totalIncome = income + projectedIncome;
      const totalExpenses = expenses + projectedExpenses;
      const balance = totalIncome - totalExpenses;

      return (
        <div className="bg-white p-4 border rounded shadow-lg">
          <p className="font-bold">{label}</p>
          <div className="space-y-2">
            <div>
              <p className="text-green-600">Actual Income: ${income.toLocaleString()}</p>
              {projectedIncome > 0 && (
                <p className="text-green-600 opacity-60 flex items-center">
                  <span>↻</span>
                  <span className="ml-1">Projected Income: ${projectedIncome.toLocaleString()}</span>
                </p>
              )}
              <p className="text-green-800 font-semibold">Total Income: ${totalIncome.toLocaleString()}</p>
            </div>
            <div>
              <p className="text-red-500">Actual Expenses: ${expenses.toLocaleString()}</p>
              {projectedExpenses > 0 && (
                <p className="text-red-500 opacity-60 flex items-center">
                  <span>↻</span>
                  <span className="ml-1">Projected Expenses: ${projectedExpenses.toLocaleString()}</span>
                </p>
              )}
              <p className="text-red-800 font-semibold">Total Expenses: ${totalExpenses.toLocaleString()}</p>
            </div>
            <div className="pt-2 border-t">
              <p className={`font-semibold ${balance >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                Balance: ${balance.toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const getRowClassName = (transactionId) => {
    if (transactionId === newlyAddedId) {
      return "border-t border-gray-200 bg-green-50 transition-colors duration-1000";
    }
    return "border-t border-gray-200";
  };

  const getExpenseRowClassName = (transactionId) => {
    if (transactionId === newlyAddedExpenseId) {
      return "border-t border-gray-200 bg-red-50 transition-colors duration-1000";
    }
    return "border-t border-gray-200";
  };

  const AddIncomeButton = () => (
    <button 
      onClick={handleAddIncome} 
      disabled={isAddingIncome}
      className="bg-blue-500 hover:bg-blue-700 text-white px-3 py-1 rounded text-sm flex items-center disabled:opacity-50 disabled:cursor-not-allowed"
    >
      {isAddingIncome ? (
        <>
          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Adding...
        </>
      ) : (
        <>
          <PlusIcon className="h-4 w-4 mr-1" />
          Add
        </>
      )}
    </button>
  );

  const AddExpenseButton = () => (
    <button 
      onClick={handleAddExpense} 
      disabled={isAddingExpense}
      className="bg-blue-500 hover:bg-blue-700 text-white px-3 py-1 rounded text-sm flex items-center disabled:opacity-50 disabled:cursor-not-allowed"
    >
      {isAddingExpense ? (
        <>
          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Adding...
        </>
      ) : (
        <>
          <PlusIcon className="h-4 w-4 mr-1" />
          Add
        </>
      )}
    </button>
  );

  const filterTransactionsByMonth = (transactions) => {
    return transactions.filter(transaction => {
      const transactionDate = new Date(transaction.date + 'T12:00:00');
      return transactionDate.getMonth() === selectedDate.getMonth() &&
             transactionDate.getFullYear() === selectedDate.getFullYear();
    });
  };

  const ProjectSelector = ({ value, onChange, disabled }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const searchInputRef = useRef(null);
    const dropdownRef = useRef(null);

    // Filter projects based on search term
    const filteredProjects = useMemo(() => {
      if (!projects) return [];
      
      try {
        const searchLower = searchTerm.toLowerCase().trim();
        return projects.filter(project => {
          try {
            return (
              (project.title || '').toLowerCase().includes(searchLower) ||
              (project.client?.name || '').toLowerCase().includes(searchLower)
            );
          } catch (err) {
            console.warn('Error filtering project:', err, project);
            return false;
          }
        });
      } catch (err) {
        console.error('Error in project filtering:', err);
        return [];
      }
    }, [projects, searchTerm]);

    // Focus search input when dropdown opens
    useEffect(() => {
      if (isOpen && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, [isOpen]);

    // Add click outside handler
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
          setSearchTerm('');
        }
      };

      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen]);

    const selectedProject = projects.find(p => p.id === value);

    return (
      <div className="relative" ref={dropdownRef}>
        {/* Trigger Button / Search Input */}
        <button
          onClick={() => !disabled && setIsOpen(!isOpen)}
          className={`w-full px-3 py-2 text-left border rounded-md ${
            disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white hover:bg-gray-50'
          }`}
          disabled={disabled}
        >
          {isOpen ? (
            <input
              ref={searchInputRef}
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full bg-transparent border-none focus:outline-none text-sm"
              placeholder="Search projects..."
              onClick={(e) => e.stopPropagation()}
            />
          ) : selectedProject ? (
            <div className="flex flex-col">
              <div className="font-medium text-sm">{selectedProject.title}</div>
              <div className="text-xs text-gray-500">
                {selectedProject.client?.name || 'No Client'}
              </div>
            </div>
          ) : (
            <span className="text-gray-500 text-sm">
              {isLoadingProjects ? "Loading projects..." : "Select a project"}
            </span>
          )}
        </button>

        {/* Dropdown Content */}
        {isOpen && (
          <div className="absolute z-50 w-full mt-1 bg-white border rounded-md shadow-lg max-h-[400px] overflow-y-auto">
            {isLoadingProjects ? (
              <div className="p-4 text-center">
                <div className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-primary rounded-full" role="status" aria-label="loading">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="mt-2 text-sm text-gray-500">Loading projects...</div>
              </div>
            ) : projectError ? (
              <div className="p-4 text-center">
                <div className="text-sm text-red-500">{projectError}</div>
                <button
                  onClick={() => fetchProjects()}
                  className="mt-2 text-sm text-primary hover:text-primary/80"
                >
                  Try again
                </button>
              </div>
            ) : (
              <>
                <div
                  className="px-3 py-2 hover:bg-gray-100 cursor-pointer border-b"
                  onClick={() => {
                    onChange('none');
                    setIsOpen(false);
                    setSearchTerm('');
                  }}
                >
                  No project
                </div>

                {filteredProjects.map((project) => (
                  <div
                    key={project.id}
                    className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      onChange(project.id);
                      setIsOpen(false);
                      setSearchTerm('');
                    }}
                  >
                    <div className="font-medium text-sm">{project.title}</div>
                    <div className="flex items-center gap-2 mt-1">
                      <span className={`px-1.5 py-0.5 text-xs rounded-full ${
                        project.status === 'In Progress' ? 'bg-green-100 text-green-800' :
                        project.status === 'Blocked' ? 'bg-red-100 text-red-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {project.status}
                      </span>
                      {project.appetite && (
                        <span className="text-xs px-1.5 py-0.5 bg-blue-50 text-blue-700 rounded-full">
                          {project.appetite.includes('Week') ? project.appetite.replace('Weeks', 'w').replace('Week', 'w') : project.appetite}
                        </span>
                      )}
                      <span className="text-xs text-gray-500">
                        {project.client?.name || 'No Client'}
                      </span>
                    </div>
                  </div>
                ))}

                {filteredProjects.length === 0 && (
                  <div className="p-2 text-sm text-gray-500 text-center">
                    No projects found
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderProjectInfo = (payment) => {
    if (!payment.project) return 'N/A';
    
    return (
      <div className="max-w-[200px]">
        <div className="font-medium truncate">{payment.project.title}</div>
        <div className="text-sm text-gray-500 truncate">{payment.project.client?.name}</div>
      </div>
    );
  };

  const getColumns = (type) => [
    {
      header: "Date",
      width: "flex-none w-20",
      cell: (payment) => {
        // Create date in local timezone and force it to be interpreted as local time
        const date = new Date(payment.date + 'T12:00:00');
        const isFuture = date > new Date();
        return (
          <div className="text-sm text-gray-600 whitespace-nowrap">
            {`${date.getMonth() + 1}/${date.getDate()}`}
          </div>
        );
      },
      inputComponent: type === 'income' ? (
        <input
          type="date"
          value={newIncome.date}
          onChange={(e) => {
            // The date input gives us the date in local timezone
            setNewIncome(prev => ({ ...prev, date: e.target.value }));
          }}
          className="w-full p-2 border rounded"
          disabled={isAddingIncome}
        />
      ) : (
        <input
          type="date"
          value={newExpense.date}
          onChange={(e) => {
            // The date input gives us the date in local timezone
            setNewExpense(prev => ({ ...prev, date: e.target.value }));
          }}
          className="w-full p-2 border rounded"
          disabled={isAddingExpense}
        />
      )
    },
    {
      header: "Project & Client",
      width: "flex-1 min-w-0",
      cell: (payment) => {
        const date = new Date(payment.date);
        const isFuture = date > new Date();
        return (
          <div className={`${isFuture ? 'opacity-50' : ''}`}>
            <div className="font-medium truncate">{payment.project?.title || 'N/A'}</div>
            <div className="text-sm text-gray-500 truncate">{payment.project?.client?.name}</div>
          </div>
        );
      },
      inputComponent: type === 'income' ? (
        <ProjectSelector
          value={newIncome.project_id}
          onChange={(value) => setNewIncome(prev => ({ ...prev, project_id: value }))}
          disabled={isAddingIncome}
        />
      ) : (
        <ProjectSelector
          value={newExpense.project_id}
          onChange={(value) => setNewExpense(prev => ({ ...prev, project_id: value }))}
          disabled={isAddingExpense}
        />
      )
    },
    {
      header: "Amount",
      width: "flex-none w-28",
      cell: (payment) => {
        const date = new Date(payment.date);
        const isFuture = date > new Date();
        return (
          <div className={`${isFuture ? 'opacity-50' : ''} text-right whitespace-nowrap`}>
            ${Number(payment.amount).toLocaleString()}
          </div>
        );
      },
      inputComponent: type === 'income' ? (
        <input
          type="number"
          value={newIncome.amount}
          onChange={(e) => setNewIncome(prev => ({ ...prev, amount: e.target.value }))}
          className="w-full p-2 border rounded"
          placeholder="Amount"
          disabled={isAddingIncome}
        />
      ) : (
        <input
          type="number"
          value={newExpense.amount}
          onChange={(e) => setNewExpense(prev => ({ ...prev, amount: e.target.value }))}
          className="w-full p-2 border rounded"
          placeholder="Amount"
          disabled={isAddingExpense}
        />
      )
    },
    {
      header: "Memo",
      width: "flex-1 max-w-[200px]",
      cell: (payment) => {
        const date = new Date(payment.date);
        const isFuture = date > new Date();
        return (
          <div className={`${isFuture ? 'opacity-50' : ''}`}>
            <div className="text-sm text-gray-600 truncate">
              {payment.memo || '-'}
            </div>
          </div>
        );
      },
      inputComponent: type === 'income' ? (
        <input
          type="text"
          value={newIncome.memo}
          onChange={(e) => setNewIncome(prev => ({ ...prev, memo: e.target.value }))}
          className="w-full p-2 border rounded"
          placeholder="Add memo"
          disabled={isAddingIncome}
        />
      ) : (
        <input
          type="text"
          value={newExpense.memo}
          onChange={(e) => setNewExpense(prev => ({ ...prev, memo: e.target.value }))}
          className="w-full p-2 border rounded"
          placeholder="Add memo"
          disabled={isAddingExpense}
        />
      )
    }
  ];

  const handleModalClose = () => {
    setIsModalOpen(false);
    setTransactionType(null);
    resetTransactionState('income');
    resetTransactionState('expense');
  };

  const handleModalSave = async () => {
    if (transactionType === 'income') {
      await handleAddIncome();
    } else {
      await handleAddExpense();
    }
    handleModalClose();
  };

  return (
    <CashFlowErrorBoundary>
      <div className="min-h-screen bg-[#F8F9FC]">
        <div className="container mx-auto px-6 py-4 space-y-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold">Cashflow Dashboard</h1>
            <MonthSelector 
              selectedDate={selectedDate}
              onDateChange={setSelectedDate}
            />
          </div>
          
          <StatsBoxes 
            transactions={[...incomeData, ...expenseData]} 
            selectedDate={selectedDate}
          />

          <div className="bg-white p-6 rounded-2xl shadow-sm">
            <h2 className="text-xl font-bold mb-2">Cashflow Overview</h2>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={cashflow} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis 
                    axisLine={false} 
                    tickFormatter={() => ''} 
                    tick={false} 
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="income" stackId="a" fill="#10B981" name="Actual Income" />
                  <Bar dataKey="projectedIncome" stackId="a" fill="rgba(16, 185, 129, 0.2)" name="Projected Income" />
                  <Bar dataKey="expenses" stackId="b" fill="#EF4444" name="Actual Expenses" />
                  <Bar dataKey="projectedExpenses" stackId="b" fill="rgba(239, 68, 68, 0.2)" name="Projected Expenses" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="grid gap-6 md:grid-cols-2">
            <TransactionTable 
              title="Income Transactions"
              description="Past and future income"
              transactions={filterTransactionsByMonth(incomeData)}
              newTransaction={newIncome}
              setNewTransaction={setNewIncome}
              isAdding={isAddingIncome}
              editingId={editingId}
              editingTransaction={editingIncome}
              setEditingTransaction={setEditingIncome}
              handleAdd={handleAddIncome}
              handleUpdate={handleUpdateIncome}
              handleEditStart={(id) => handleEditStart(id, 'income')}
              handleEditCancel={handleEditCancel}
              handleDelete={handleDeleteIncome}
              handleDuplicate={handleDuplicateTransaction}
              getRowClassName={getRowClassName}
              type="income"
              isSaving={isSaving}
              columns={getColumns('income')}
              className="bg-white rounded-2xl shadow-sm min-w-0"
              ProjectSelector={ProjectSelector}
            />

            <TransactionTable 
              title="Expense Transactions"
              description="Past and future expenses"
              transactions={filterTransactionsByMonth(expenseData)}
              newTransaction={newExpense}
              setNewTransaction={setNewExpense}
              isAdding={isAddingExpense}
              editingId={editingId}
              editingTransaction={editingExpense}
              setEditingTransaction={setEditingExpense}
              handleAdd={handleAddExpense}
              handleUpdate={handleUpdateExpense}
              handleEditStart={(id) => handleEditStart(id, 'expense')}
              handleEditCancel={handleEditCancel}
              handleDelete={handleDeleteExpense}
              handleDuplicate={handleDuplicateTransaction}
              getRowClassName={getExpenseRowClassName}
              type="expense"
              isSaving={isSaving}
              columns={getColumns('expense')}
              className="bg-white rounded-2xl shadow-sm min-w-0"
              ProjectSelector={ProjectSelector}
            />
          </div>
        </div>
        
        <TransactionModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          title={`Add ${transactionType === 'income' ? 'Income' : 'Expense'}`}
          transaction={transactionType === 'income' ? newIncome : newExpense}
          setTransaction={transactionType === 'income' ? setNewIncome : setNewExpense}
          handleSave={handleModalSave}
          isSaving={transactionType === 'income' ? isAddingIncome : isAddingExpense}
          type={transactionType}
          ProjectSelector={ProjectSelector}
        />
      </div>
    </CashFlowErrorBoundary>
  );
};

export default CashFlow;
