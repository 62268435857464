import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Home, ChevronRight } from 'lucide-react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ProjectCard from '../components/ProjectCard';
import FilterDropdown from '../components/FilterDropdown';
import Loader from '../components/Loader';
import { supabase } from '../utils/supabaseClient';

const statusColors = {
  'To Do': 'bg-red-500',
  'In Progress': 'bg-yellow-500',
  'In Review': 'bg-purple-500',
  'Completed': 'bg-green-500',
  'Backlog': 'bg-gray-500',
  'Inbox': 'bg-gray-400',
  'Shaping': 'bg-yellow-500',
  'Dev Ready': 'bg-gray-600',
  'Blocked': 'bg-red-500'
};

const STATUS_ORDER = [
  'Inbox',
  'Shaping',
  'Dev Ready',
  'In Progress',
  'In Review',
  'Completed',
  'Blocked'
];

const Column = ({ status, projects, onClientClick, filterParams }) => {
  const getStatusColor = (status) => {
    return statusColors[status] || 'bg-gray-400';
  };

  return (
    <div className="min-w-[340px] mr-6">
      <h3 className="text-lg font-semibold mb-4 flex items-center">
        <div className="flex items-center">
          <span className={`h-3 w-3 rounded-full mr-2 ${getStatusColor(status)}`}></span>
          <span className="text-gray-800">
            {status}
          </span>
        </div>
        <span className="ml-2 text-sm font-medium px-2 py-1 rounded-full bg-gray-200 text-gray-800">
          {projects.length}
        </span>
      </h3>
      <Droppable droppableId={status}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="min-h-[200px] space-y-4 rounded-lg"
          >
            {projects.map((project, index) => (
              <ProjectCard
                key={project.id}
                project={project}
                index={index}
                onClientClick={onClientClick}
                filterParams={filterParams}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

const Breadcrumbs = ({ clientName, resetFilters }) => {
  const pages = [
    { name: 'Projects', href: '/projects', current: !clientName },
    ...(clientName ? [{ name: clientName, href: `/projects?client=${encodeURIComponent(clientName)}`, current: true }] : []),
  ];

  return (
    <nav aria-label="Breadcrumb" className="flex mb-4">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link 
              to="/projects" 
              className="text-gray-400 hover:text-gray-500"
              onClick={(e) => {
                e.preventDefault();
                resetFilters();
              }}
            >
              <Home className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Projects</span>
            </Link>
          </div>
        </li>
        {pages.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRight className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              {page.current ? (
                <span className="ml-4 text-sm font-medium text-gray-500" aria-current="page">
                  {page.name}
                </span>
              ) : (
                <Link
                  to={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  onClick={(e) => {
                    e.preventDefault();
                    resetFilters();
                  }}
                >
                  {page.name}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    statuses: [],
    clients: [],
    appetites: []
  });

  const [availableFilters, setAvailableFilters] = useState({
    statuses: [],
    clients: [],
    appetites: []
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [clientFilter, setClientFilter] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetchProjects();
  }, []); // Fetch projects only once on component mount

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const clientParam = searchParams.get('client');
    if (clientParam) {
      setClientFilter(clientParam);
      setFilters(prev => ({ ...prev, clients: [clientParam] }));
    } else {
      setClientFilter(null);
      setFilters(prev => ({ ...prev, clients: [] }));
    }
  }, [location.search]);

  const fetchProjects = async () => {
    setLoading(true);
    setError(null);
    try {
      console.log('Fetching projects from Supabase...');
      
      // Fetch projects with client information
      const { data: projectsData, error: projectsError } = await supabase
        .from('projects')
        .select(`
          *,
          client:clients(
            id,
            name
          )
        `)
        .eq('is_active', true)
        .order('created_at', { ascending: false });

      if (projectsError) throw projectsError;

      console.log('Projects fetched successfully:', projectsData);
      setProjects(projectsData);
      
      // Extract unique values for filters
      const statuses = [...new Set(projectsData.map(project => project.status))];
      const clients = [...new Set(projectsData.map(project => project.client?.name).filter(Boolean))];
      const appetites = [...new Set(projectsData.map(project => project.appetite).filter(Boolean))];

      setAvailableFilters({
        statuses,
        clients,
        appetites
      });

    } catch (err) {
      console.error('Error fetching projects:', err);
      setError(`Failed to fetch projects: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (filterType, selectedOptions) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: selectedOptions
    }));
  };

  const handleClientClick = (e, clientName) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/projects?client=${encodeURIComponent(clientName)}`);
  };

  const filteredProjects = useMemo(() => {
    return projects.filter(project => {
      return (
        (filters.statuses.length === 0 || filters.statuses.includes(project.status)) &&
        (filters.clients.length === 0 || (project.client && filters.clients.includes(project.client.name))) &&
        (filters.appetites.length === 0 || filters.appetites.includes(project.appetite))
      );
    });
  }, [projects, filters]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { draggableId, destination } = result;
    const newStatus = destination.droppableId;

    try {
      // Update the project status in Supabase
      const { error } = await supabase
        .from('projects')
        .update({ 
          status: newStatus,
          updated_at: new Date().toISOString()
        })
        .eq('id', draggableId);

      if (error) throw error;

      // Update local state
      setProjects(prevProjects => 
        prevProjects.map(project => 
          project.id === draggableId 
            ? { ...project, status: newStatus }
            : project
        )
      );
    } catch (err) {
      console.error('Error updating project status:', err);
      // You might want to show an error toast here
    }
  };

  const resetFilters = () => {
    setFilters({
      statuses: [],
      clients: [],
      appetites: []
    });
    setClientFilter(null);
    navigate('/projects');
  };

  const filterParams = useMemo(() => {
    const params = new URLSearchParams();
    if (filters.statuses.length) params.append('statuses', filters.statuses.join(','));
    if (filters.clients.length) params.append('clients', filters.clients.join(','));
    if (filters.appetites.length) params.append('appetites', filters.appetites.join(','));
    return params.toString() ? `?${params.toString()}` : '';
  }, [filters]);

  return (
    <div className="flex flex-col h-screen bg-app-bg">
      <div className="flex-shrink-0">
        <div className="px-4 sm:px-8 py-8">
          <div className="mb-6 w-full">
            <Breadcrumbs clientName={clientFilter} resetFilters={resetFilters} />
          </div>
          
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 w-full">
            <h1 className="text-2xl font-bold mb-4 sm:mb-0">Projects</h1>
            <div className="flex flex-wrap items-center gap-4">
              <FilterDropdown
                options={availableFilters.statuses}
                selectedOptions={filters.statuses}
                onChange={(selected) => handleFilterChange('statuses', selected)}
                placeholder="Status"
              />
              <FilterDropdown
                options={availableFilters.clients}
                selectedOptions={filters.clients}
                onChange={(selected) => handleFilterChange('clients', selected)}
                placeholder="Client"
              />
              <FilterDropdown
                options={availableFilters.appetites}
                selectedOptions={filters.appetites}
                onChange={(selected) => handleFilterChange('appetites', selected)}
                placeholder="Appetite"
              />
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => console.log('New Project button clicked')}
              >
                New Project
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-grow overflow-x-auto">
        <DragDropContext onDragEnd={onDragEnd}>
          {loading ? (
            <Loader />
          ) : error ? (
            <div className="text-red-500 text-center">{error}</div>
          ) : (
            <div className="flex space-x-4 px-4 sm:px-8 py-6 w-full">
              {STATUS_ORDER.map(status => (
                <Column
                  key={status}
                  status={status}
                  projects={filteredProjects.filter(project => project.status === status)}
                  onClientClick={handleClientClick}
                  filterParams={filterParams}
                />
              ))}
            </div>
          )}
        </DragDropContext>
      </div>
    </div>
  );
};

export default Projects;