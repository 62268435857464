import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { supabase } from './utils/supabaseClient';
import Projects from './pages/Projects';
import ProjectDetail from './pages/ProjectDetail';
import Pricing from './pages/Pricing';
import Pods from './pages/Pods';
import CashFlow from './pages/CashFlow';
import TimeTracking from './pages/TimeTracking';
import Login from './pages/Login';
import Sidebar from './components/Sidebar';
import Users from './pages/Users';
import PendingApproval from './pages/PendingApproval';
import AuthCallback from './pages/auth/callback';
import ProjectConnections from './pages/ProjectConnections';

function App() {
  const [session, setSession] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  const createNewUser = useCallback(async (user) => {
    console.log('Starting createNewUser');
    try {
      if (!user) throw new Error('No user found');
      console.log('User data from auth:', user);

      const { data, error } = await supabase
        .from('users')
        .insert({
          auth_id: user.id,
          email: user.email,
          role: 'pending',
        })
        .single();

      if (error) throw error;

      console.log('New user created:', data);
      setUserRole('pending');
    } catch (error) {
      console.error('Error in createNewUser:', error);
      setError('Failed to create user. Please try again.');
      setUserRole('pending');
    } finally {
      console.log('Finished createNewUser');
      setLoading(false);
    }
  }, []);

  const fetchUserRole = useCallback(
    async (user) => {
      console.log('Starting fetchUserRole');
      try {
        if (!user) throw new Error('No user found');
        const userId = user.id;

        const { data, error } = await supabase
          .from('users')
          .select('role')
          .eq('auth_id', userId)
          .single();

        if (error) {
          if (error.code === 'PGRST116' || error.details.includes('Results contain 0 rows')) {
            console.log('No user found, creating new user');
            await createNewUser(user);
          } else {
            throw error;
          }
        } else if (data) {
          console.log('User role found:', data.role);
          setUserRole(data.role);
          setLoading(false); // Move the loading state update here
        } else {
          console.log('No data returned, creating new user');
          await createNewUser(user);
        }
      } catch (error) {
        console.error('Error in fetchUserRole:', error);
        setError('Failed to fetch user role. Please try again.');
        setUserRole('pending');
        setLoading(false); // Ensure loading is set to false on error
      } finally {
        console.log('Finished fetchUserRole');
      }
    },
    [createNewUser]
  );

  useEffect(() => {
    let isMounted = true;

    const init = async () => {
      setLoading(true);

      // Get the current session
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (error) {
        console.error('Error getting session:', error);
        setLoading(false); // Ensure loading is set to false on error
      }

      if (session && isMounted) {
        console.log('Session found:', session); // Add this line
        setSession(session);
        setUser(session.user);
        await fetchUserRole(session.user);
      } else {
        console.log('No session found'); // Add this line
        setLoading(false); // Ensure loading is set to false if no session
      }

      // Listen to auth state changes
      const { data: authListener } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          console.log('Auth state changed:', event, session);
          if (event === 'SIGNED_IN' && session) {
            setSession(session);
            setUser(session.user);
            await fetchUserRole(session.user);
          } else if (event === 'SIGNED_OUT') {
            setSession(null);
            setUser(null);
            setUserRole(null);
            setLoading(false);
          }
        }
      );

      return () => {
        isMounted = false;
        if (authListener && authListener.subscription) {
          authListener.subscription.unsubscribe();
        }
      };
    };

    init();
  }, [fetchUserRole]);

  const handleRoleChange = (newRole) => {
    setUserRole(newRole);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <div className="mb-4">Loading... (Session: {session ? 'Yes' : 'No'}, User: {user ? 'Yes' : 'No'})</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <div className="mb-4 text-red-500">Error: {error}</div>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <div className="flex">
        {session && (
          <Sidebar
            userRole={userRole}
            user={user}
            onRoleChange={handleRoleChange}
          />
        )}
        <div className={`flex-1 ${session ? 'ml-64' : ''} max-w-full bg-app-bg`}>
          <Routes>
            {/* Remove API route handling from React Router */}
            <Route
              path="/login"
              element={
                session ? (
                  userRole === 'admin' ? (
                    <Navigate to="/cashflow" />
                  ) : (
                    <Navigate to="/projects" />
                  )
                ) : (
                  <Login />
                )
              }
            />
            <Route 
              path="/auth/callback" 
              element={<AuthCallback />} 
            />
            <Route
              path="/projects"
              element={session ? <Projects /> : <Navigate to="/login" />}
            />
            <Route
              path="/projects/:id"
              element={session ? <ProjectDetail /> : <Navigate to="/login" />}
            />
            <Route
              path="/pricing"
              element={session ? <Pricing /> : <Navigate to="/login" />}
            />
            <Route
              path="/pods"
              element={session ? <Pods /> : <Navigate to="/login" />}
            />
            <Route
              path="/cashflow"
              element={session ? <CashFlow /> : <Navigate to="/login" />}
            />
            <Route
              path="/time-tracking"
              element={session ? <TimeTracking /> : <Navigate to="/login" />}
            />
            <Route
              path="/users"
              element={
                session && userRole === 'admin' ? (
                  <Users />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/pending-approval"
              element={
                session && userRole === 'pending' ? (
                  <PendingApproval />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/project-connections"
              element={
                session && userRole === 'admin' ? (
                  <ProjectConnections />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="*"
              element={
                session ? (
                  userRole === 'admin' ? (
                    <Navigate to="/cashflow" />
                  ) : (
                    <Navigate to="/projects" />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
